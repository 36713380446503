/* ==========================================================================
   #QUANTITY SELECTOR
   ========================================================================== */
$quantity-selector-border-color: $color-grey !default;
$quantity-selector-border-radius: $global-radius !default;
$quantity-selector-border-width: 1px !default;
$quantity-selector-border-style: solid !default;
$quantity-selector-min-width: pinpoint-spacing(base) !default;
$quantity-selector-min-height: pinpoint-spacing(large) !default;
$quantity-selector-input-width: pinpoint-spacing(huge) !default;
$quantity-selector-minus-content: "-" !default;
$quantity-selector-plus-content: "+" !default;

.control.qty,
.details-qty.qty,
.field.qty .control {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    max-width: fit-content;
    padding: 0 pinpoint-spacing(small);
    border: $quantity-selector-border-style $quantity-selector-border-width $quantity-selector-border-color;
    border-radius: $quantity-selector-border-radius;
    background-color: $color-white;

    &::before,
    &::after {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: $quantity-selector-min-width;
        min-height: $quantity-selector-min-height;
        cursor: pointer;
    }

    &::before {
        content: $quantity-selector-minus-content;
    }

    &::after {
        content: $quantity-selector-plus-content;
    }

    input[type=number] {
        @include pinpoint-font-size(18px);
        text-align: center;
        border: 0;

        // Firefox fix for input[type=number] up/down arrows
        -moz-appearance: textfield;

        // Webkit fix for input[type=number] up/down arrows
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }
    }

    .label {
        @include pinpoint-hidden-visually();
    }

    .quantity-decrease,
    .quantity-increase {
        position: absolute;
        top: 9px;
        bottom: 0;
        min-width: $quantity-selector-min-width;
        cursor: pointer;
        z-index: 1;
    }

    .quantity-decrease {
        left: 0;
    }

    .quantity-increase {
        right: 0;
    }
}

.field.qty .control {
    margin-top: 8px;
    min-width: 252px;
    max-width: 252px;
    height: 56px;
}

@import "quantity-selector__extend";
