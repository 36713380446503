/* ==========================================================================
   #TEXT-TRANSFORMS
   ========================================================================== */

/**
 * Utility classes to easily add text transforms:
 *
 *   <p class="u-capitalize">ensure this looks purrty</p>
 *
 */

.u-capitalize {
  text-transform: capitalize !important; // sass-lint:disable-line no-important
}

.u-uppercase {
  text-transform: uppercase !important; // sass-lint:disable-line no-important
}

.u-lowercase {
  text-transform: lowercase !important; // sass-lint:disable-line no-important
}
