.cms-home {
    .pb-homepage-banner {
        .pagebuilder-column {
            @include mq($from: tablet, $until: desktop) {
                width: 50% !important;

                [data-content-type="image"] {
                    img {
                        height: auto !important;
                    }
                }
            }
        }
    }
}
