/* ==========================================================================
   #WIDTHS
   ========================================================================== */

/**
 * pinpoint generates a series of utility classes that give a fluid width to
 * whichever element they’re applied, e.g.:
 *
 *   <img src="" alt="" class="u-1/2" />
 *
 * These classes are most commonly used in conjunction with our layout system,
 * e.g.:
 *
 *   <div class="o-layout__item  u-1/2">
 *
 * By default, pinpoint will also generate responsive variants of each of these
 * classes by using your Sass MQ configuration, e.g.:
 *
 *   <div class="o-layout__item  u-1/1  u-1/2@tablet  u-1/3@desktop">
 *
 * Optionally, pinpoint can generate offset classes which can push and pull
 * elements left and right by a specified amount, e.g.:
 *
 *   <div class="o-layout__item  u-2/3  u-pull-1/3">
 *
 * This is useful for making very granular changes to the rendered order of
 * items in a layout.
 *
 * N.B. This option is turned off by default.
 */

@use "sass:math";

// Which fractions would you like in your grid system(s)? By default, pinpoint
// provides you fractions of one whole, halves, thirds, quarters and fifths,
// e.g.:
//
//   .u-1/2
//   .u-2/5
//   .u-3/4
//   .u-2/3

$pinpoint-fractions: 1 2 3 4 5 !default;

// Optionally, pinpoint can generate classes to offset items by a certain width.
// Would you like to generate these types of class as well? E.g.:
//
//   .u-push-1/3
//   .u-pull-2/4
//   .u-pull-1/5
//   .u-push-2/3

$pinpoint-offsets: false !default;

// By default, pinpoint uses fractions-like classes like `<div class="u-1/4">`.
// You can change the `/` to whatever you fancy with this variable.
$pinpoint-widths-delimiter: \/ !default;

// When using Sass-MQ, this defines the separator for the breakpoints suffix
// in the class name. By default, we are generating the responsive suffixes
// for the classes with a `@` symbol so you get classes like:
// <div class="u-3/12@mobile">
$pinpoint-widths-breakpoint-separator: \@ !default;

// A mixin to spit out our width classes. Pass in the columns we want the widths
// to have, and an optional suffix for responsive widths. E.g. to create thirds
// and quarters for a small breakpoint:
//
// @include widths(3 4, -sm);

@mixin pinpoint-widths($columns, $breakpoint: null) {
  // Loop through the number of columns for each denominator of our fractions.
  @each $denominator in $columns {
    // Begin creating a numerator for our fraction up until we hit the
    // denominator.
    @for $numerator from 1 through $denominator {
      // Build a class in the format `.u-3/4[@<breakpoint>]`.

      .u-#{$numerator}#{$pinpoint-widths-delimiter}#{$denominator}#{$breakpoint} {
        width: math.div($numerator, $denominator) * 100% !important; // sass-lint:disable-line no-important
      }

      @if ($pinpoint-offsets == true) {
        /**
         * 1. Reset any leftover or conflicting `left`/`right` values.
         */

        // Build a class in the format `.u-push-1/2[@<breakpoint>]`.

        .u-push-#{$numerator}#{$pinpoint-widths-delimiter}#{$denominator}#{$breakpoint} {
          position: relative !important; // sass-lint:disable-line no-important
          right: auto !important; // sass-lint:disable-line no-important /* [1] */
          left: ($numerator / $denominator) * 100% !important; // sass-lint:disable-line no-important
        }

        // Build a class in the format `.u-pull-5/6[@<breakpoint>]`.

        .u-pull-#{$numerator}#{$pinpoint-widths-delimiter}#{$denominator}#{$breakpoint} {
          position: relative !important; // sass-lint:disable-line no-important
          right: ($numerator / $denominator) * 100% !important; // sass-lint:disable-line no-important
          left: auto !important; // sass-lint:disable-line no-important /* [1] */
        }
      }
    }
  }

  @if ($pinpoint-offsets == true and $breakpoint != null) {
    // Create auto push and pull classes.

    .u-push-none#{$breakpoint} {
      left: auto !important; // sass-lint:disable-line no-important
    }

    .u-pull-none#{$breakpoint} {
      right: auto !important; // sass-lint:disable-line no-important
    }
  }
}

/**
 * A series of width helper classes that you can use to size things like grid
 * systems. Classes take a fraction-like format (e.g. `.u-2/3`). Use these in
 * your markup:
 *
 * <div class="u-7/12">
 *
 * The following will generate widths helper classes based on the fractions
 * defined in the `$pinpoint-fractions` list.
 */

@include pinpoint-widths($pinpoint-fractions);

/**
 * If we’re using Sass-MQ, automatically generate grid system(s) for each of our
 * defined breakpoints, and give them a Responsive Suffix, e.g.:
 *
 * <div class="u-3/12@mobile">
 */

@if (variable-exists(mq-breakpoints)) {
  @each $pinpoint-bp-name, $pinpoint-bp-value in $mq-breakpoints {
    @include mq($from: $pinpoint-bp-name) {
      @include pinpoint-widths(
        $pinpoint-fractions,
        #{$pinpoint-widths-breakpoint-separator}#{$pinpoint-bp-name}
      );
    }
  }
}
