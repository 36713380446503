.toolbar-products {
  .modes {
    display: none;
  }

  .toolbar-amount {
    display: block !important;
    font-size: 14px;
  }

  .field.limiter {
    display: none;
  }

  .toolbar-sorter {
    .sorter-options {
      border-color: $border-colour;

      @include mq($from: desktop) {
        min-height: 56px;
      }
    }
  }
}
