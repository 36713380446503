$sticky-cart-icon-background-colour: #373737;
$header-background-colour: linear-gradient(180deg, rgba(0, 113, 48, 1) 0%, rgba(0, 150, 64, 1) 40%, rgba(0, 150, 64, 1) 100%) !default;
$header-background-colour--mobile: linear-gradient(180deg, rgba(0, 113, 48, 1) 0%, rgba(0, 150, 64, 1) 40%, rgba(0, 150, 64, 1) 100%) !default;
$header-links-font-weight: bold !default;
$header-links-color-hover: $color-primary !default;
$header-content-column-gap--tablet: 3px !default;
$header-content-column-gap--desktop: 16px !default;
$panel-color: $color-black !default;

.page-header:not(.checkout-index-index .page-header) {
    background: $header-background-colour--mobile;

    @include mq($from: tablet) {
        background: $header-background-colour;
    }
}

.panel.header {
    @include pinpoint-constrained-widths();
    display: flex;
    justify-content: flex-end;
    gap: 0;
    z-index: 1000;

    .header.links {
        @include mq($from: desktop) {
            font-weight: $header-links-font-weight;
        }
    }
}

.panel.wrapper {
    position: fixed;
    width: 100%;
    z-index: 1002;
    padding-top: 0;
    padding-bottom: 0;

    @include mq($until: desktop) {
        display: none;
    }

    // override base class styles to reduce need for !important
    .panel.header .header.links {
        @include mq($from: tablet) {
            gap: 32px;

            a:hover {
                color: $header-links-color-hover;
            }
        }
    }

    .minicart-wrapper {
        .action.showcart {
            display: none;
            width: 40px;
            padding: 8px 0;
            background-color: $sticky-cart-icon-background-colour;
            border-radius: 0;

            .text {
                display: none;
            }
        }

        .counter {
            top: -9px;
            right: -3px;

            .counter-number {
                width: 16px;
                height: 16px;
                line-height: 16px;
            }

            &:not(.header.content .counter) {
                top: -3px;
                right: 4px;
            }
        }
    }

    // .panel.wrapper.sticky
    &.sticky {
        padding-top: 0;
        padding-bottom: 0;

        .minicart-wrapper .action.showcart {
            display: inline-flex;
            margin-right: 0;
        }
    }
}

.header.content:not(.checkout-index-index .header.content) {
    margin-top: 36px;
    padding: 16px;

    @include mq($until: desktop) {
        width: 100%;
        background: $header-background-colour--mobile;
        top: 0;
        z-index: 1000;
        margin-top: 0;
    }

    @include mq($from: tablet) {
        column-gap: $header-content-column-gap--tablet;
        row-gap: 0;
        padding: 0 16px 16px;
    }

    @include mq($from: desktop) {
        column-gap: $header-content-column-gap--desktop;
        padding: 16px;
    }

    .action.book-appointment,
    .action.nav-toggle,
    .action.store-locator,
    .action.my-account,
    .action.quote-list,
    .action.showcart {
        padding: 6px;
    }

    &.sticky {
        position: fixed;
        opacity: 1;
        animation-name: fadeInOpacity;
        animation-iteration-count: 1;
        animation-timing-function: ease-in;
        animation-duration: 0.5s;

        @include mq($until: desktop) {
            box-shadow: 0 3px 6px rgba(0, 98, 176, 0.1);
        }
    }
}

.header.content:not(.checkout-index-index .header.content).sticky {
    @include mq($from: desktop) {
        display: none;
    }
}

.page-wrapper .main-menu {
    @include mq($until: tablet) {
        top: 118px;
        height: calc(100% - 118px);
    }

    @include mq($until: desktop) {
        width: 100%;
        position: fixed;
        z-index: 1000;
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 40px;
    }

    @include mq($from: tablet, $until: desktop) {
        top: 143px;
        height: calc(100% - 143px);
    }
}

.main-menu.sticky {
    position: fixed;
    width: 100%;
    z-index: 1001; // This is a quick fix considering the previous values. TODO: rethink z-index architecture

    @include mq($from: desktop) {
        top: 36px;
    }
}


.header.links {
    flex: 1;
}

.header--checkout .action.nav-toggle {
    display: none;
}

@keyframes fadeInOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
