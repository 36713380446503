@media print {
    @page {
        size: portrait;
    }
}

body.page-print {
    .action.nav-toggle {
        display: none;
    }

    .logo {
        @include pinpoint-constrained-widths();
        display: inline-block;
        float: none;
        margin: 25px auto 25px 12px;
        position: relative;
        max-width: none;

        @include mq($from: tablet) {
            max-width: none;
        }

        &:after {
            display: none;
        }

        img {
            width: 200px;
            display: block;
            height: auto;
            max-width: 100%;
            max-height: inherit;
        }
    }

    .block-order-details-view {
        &:before,
        &:after {
            @include mq($from: tablet) {
                content: "";
                display: table;
                clear: both;
            }
        }

        .block-title {
            border-bottom: 1px solid #c6c6c6;
            margin-bottom: 25px;
            padding-bottom: 10px;

            &:before,
            &:after {
                @include mq($until: tablet) {
                    content: "";
                    display: table;
                    clear: both;
                }
            }

            strong {
                font-size: 24px;
                font-weight: normal;
                display: block;
            }
        }

        .block-content {
            display: flex;
            flex-wrap: wrap;
            gap: 20px;

            .box {
                flex: 1 1 auto;
            }
        }
    }

    .table-wrapper {
        .orders-recent {
            @include mq($from: tablet) {
                margin-top: -25px;
            }
        }
    }

    .order-links {
        &:before,
        &:after {
            @include mq($from: tablet) {
                content: "";
                display: table;
                clear: both;
            }
        }

        .item {
            @include mq($from: tablet) {
                float: left;
                margin: 0 -1px 0 0;
            }

            a {
                @include mq($from: tablet) {
                    padding: 1px 35px;
                }
            }

            strong {
                @include mq($from: tablet) {
                    border-bottom: 0;
                    margin-bottom: -1px;
                    padding: 1px 35px 2px 35px;
                }
            }
        }
    }

    .order-actions-toolbar {
        .action.print {
            @include mq($from: tablet) {
                display: block;
                float: right;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    &.account,
    [class^='sales-guest-'],
    .sales-guest-view {
        .page-main {
            .page-title-wrapper {
                .order-date {
                    font-size: 16px;
                    margin-top: 20px;
                    margin-bottom: 10px;

                    @include mq($from: tablet) {
                        margin: 20px 0 18px;
                    }

                    .label {
                        display: none;
                    }
                }
            }
        }

        .column.main .block.block-order-details-view {
            &:before,
            &:after {
                @include mq($from: tablet) {
                    content: "";
                    display: table;
                    clear: both;
                }
            }

            .block-content:not(.widget) .box {
                @include mq($from: tablet) {
                    box-sizing: border-box;
                    clear: none;
                    float: left;
                    width: 100%/4;
                }
            }
        }
    }

    .order-status {
        padding: 0 10px;
        margin: 20px 0 0;
        border: 2px solid $border-colour;
        border-radius: 3px;
        display: inline-flex;
        text-transform: uppercase;
        line-height: 0;
        align-items: center;
        justify-content: center;
        height: 30px;
        font-size: 14px;

        &.status-Pending,
        &.status-Processing {
            background: #E0F5F5;
            color: #3CA7A5;
            border-color: #3CA7A5;
        }

        &.status-Complete {
            background: #009640;
            color: $color-white;
            border-color: #009640;
        }

        &.status-Closed {
            background: #F4F6F8;
            color: #647380;
            border-color: #647380;
        }
    }

    .order-details-items {
        border: 1px solid $border-colour;
        margin-bottom: 40px;
        padding: 10px;

        @include mq($from: tablet) {
            padding: 25px;
        }

        .order-title {
            border-bottom: 1px solid $border-colour;
            padding: 0 0 10px;

            > strong {
                font-size: 24px;
                font-weight: normal;
                display: block;

                @include mq($from: tablet) {
                    display: none;
                }
            }

            .action {
                display: inline-block;
                margin: 12px 0 0 30px;

                &.track {
                    float: right;
                }
            }
        }

        &.ordered {
            .order-title {
                @include mq($from: tablet) {
                    display: none;
                }
            }
        }

        .table-wrapper {
            margin: 0;
            margin-bottom: 30px;
            border-top: 0;
            border-bottom: 1px solid $border-colour;
            overflow-x: auto;

            @include mq($until: tablet) {
                border-top: 0;
                border-bottom: 0;
                overflow-y: hidden;
                width: 100%;
                position: relative;
            }

            + .actions-toolbar {
                display: none;
            }

            &:not(:last-child) {
                margin-bottom: 30px;
            }

            &.order-items-shipment {
                overflow: visible;
            }
        }

        .table-order-items {
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            max-width: 100%;
            border: none;

            @include mq($until: tablet) {
                display: block;
            }

            @include mq($from: tablet) {
                display: table;
            }

            > caption {
                border: 0;
                clip: rect(0, 0, 0, 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            > thead {
                display: block;

                @include mq($from: tablet) {
                    display: table-header-group;
                }

                tr {
                    @include mq($until: tablet) {
                        display: block;
                    }

                    > th {
                        font-weight: bold;
                        border: none;
                        padding: 11px 10px;
                        vertical-align: bottom;
                        text-align: left;
                    }
                }

                .product-item-name {
                    margin: 0 0 10px;

                    @include mq($until: tablet) {
                        display: inline-block;
                        margin: 0;
                    }
                }

                .action.show {
                    @include mq($until: tablet) {
                        margin-top: 0;
                    }
                }

                .options-label {
                    @include mq($until: tablet) {
                        display: none;
                    }
                }
            }

            > thead > tr > th {
                border-bottom: 1px solid $border-colour;
            }

            tbody {
                border-bottom: 1px solid $border-colour;
                margin-top: 20px;
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-top: 0;

                .account & {
                    tr:nth-child(even) td {
                        background: none;
                    }
                }

                tr {
                    @include mq($until: tablet) {
                        display: block;
                    }
                }

                td {
                    padding: 11px 10px;

                    @include mq($from: tablet) {
                        padding: 20px 10px;
                        vertical-align: top;
                    }
                }

                .col {
                    &.label {
                        font-weight: bold;
                        padding-bottom: 5px;
                        padding-top: 0;
                    }

                    &.options {
                        padding: 10px 10px 15px;
                    }

                    &.actions:before,
                    &.options:before {
                        @include mq($until: tablet) {
                            display: none;
                        }
                    }
                }

                > .item-parent {
                    td {
                        padding-bottom: 5px;
                    }

                    + tr td {
                        padding-top: 5px;
                    }
                }

                .item-options-container td {
                    padding-bottom: 15px;
                    padding-top: 0;
                }
            }

            tfoot {
                background: $color-grey--xlight;

                tr {
                    @include mq($until: tablet) {
                        display: block;
                    }

                    &:before,
                    &:after {
                        @include mq($until: tablet) {
                            content: "";
                            display: table;
                            clear: both;
                        }
                    }
                }

                th {
                    padding: 11px 10px;
                }

                td {
                    padding: 11px 10px;
                    background-color: inherit;
                }

                .mark {
                    @include mq($until: tablet) {
                        box-sizing: border-box;
                        float: left;
                        text-align: left;
                        width: 70%;
                    }
                }

                .amount {
                    @include mq($until: tablet) {
                        box-sizing: border-box;
                        float: left;
                        text-align: right;
                        width: 30%;
                    }
                }
            }

            &:not(.totals):not(.cart):not(.table-comparison) {
                > thead > tr > th {
                    @include mq($until: tablet) {
                        display: none;
                    }
                }

                > tbody {
                    @include mq($until: tablet) {
                        display: block;
                    }

                    > tr {
                        td {
                            font-size: 14px;

                            @include mq($until: tablet) {
                                display: block;
                                padding: 5px 10px;
                            }

                            @include mq($from: tablet) {
                                font-size: 16px;
                            }
                        }

                        td[data-th]:before {
                            @include mq($until: tablet) {
                                padding-right: 10px;
                                content: attr(data-th) ': ';
                                display: inline-block;
                                color: $color-black;
                                font-weight: 700;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
        }

        .options-label + .item-options-container,
        .item-options-container + .item-options-container {
            &[data-th]:before {
                @include mq($until: tablet) {
                    content: attr(data-th) ':';
                    display: block;
                    font-weight: bold;
                    padding-left: 10px;
                }
            }

            .col {
                @include mq($until: tablet) {
                    font-size: 12px;
                    padding: 0 10px;
                }

                &:first-child {
                    @include mq($until: tablet) {
                        padding-top: 3px;
                    }
                }

                &:last-child {
                    @include mq($until: tablet) {
                        padding-bottom: 20px;
                    }
                }
            }
        }

        .item-options {
            margin: 0;
            display: block;
            color: $color-grey;

            > dt {
                font-weight: bold;
                float: left;
                clear: left;
                margin-right: 6px;
                color: $color-black;

                &:after {
                    content: ':';
                }
            }

            dd {
                margin: 0 0 6px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.links {
                dt {
                    display: inline-block;

                    &:after {
                        content: ': ';
                    }
                }

                dd {
                    margin: 0;
                }
            }
        }

        thead {
            @include mq($until: tablet) {
                display: block;
            }

            tr {
                @include mq($until: tablet) {
                    display: block;
                }
            }
        }

        .col {
            &.price,
            &.subtotal {
                .price-including-tax,
                .price-excluding-tax {
                    color: $color-primary;
                    font-size: 16px;
                    display: block;
                    line-height: 1;
                    white-space: nowrap;

                    @include mq($until: tablet) {
                        display: inline-block;
                        margin-right: 6px;
                        font-size: 14px;
                    }

                    .price {
                        font-weight: bold;
                    }
                }
            }

            &.price,
            &.qty {
                @include mq($from: desktop) {
                    text-align: right;
                }
            }

            &.subtotal {
                @include mq($from: tablet) {
                    text-align: right;
                }
            }
        }

        tbody td {
            @include mq($from: tablet) {
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }

        .data.table .col.options {
            @include mq($until: tablet) {
                padding: 0 10px 15px;

                &:before {
                    display: none;
                }
            }
        }

        .cart-tax-total {
            cursor: pointer;
            padding-right: 10px;
            position: relative;

            &:after {
                position: absolute;
                right: -2px;
                top: 4px;
            }
        }

        tfoot {
            @include mq($until: tablet) {
                display: block;
            }

            td {
                background-color: $color-grey--light;
            }

            .amount,
            .mark {
                @include mq($from: tablet) {
                    text-align: right;
                }
            }
        }

        .action {
            @include mq($until: tablet) {
                margin-top: 10px;
            }

            &.show {
                color: $color-primary;
            }
        }

        .product-item-name {
            font-size: 16px;
            margin: 0 0 10px;
            display: inline-block;
        }

        .items-qty {
            margin: 0;
            padding: 0;
            list-style: none;

            @include mq($until: tablet) {
                display: inline-block;
                vertical-align: top;
            }

            > li {
                margin: 0;
            }

            .item {
                white-space: nowrap;
            }

            .title {
                &:after {
                    content: ': ';
                }
            }
        }

        .pages-items {
            .item {
                &:first-child,
                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .order-pager-wrapper {
        @include mq($until: tablet) {
            display: block;
        }

        .order-pager-wrapper-top {
            @include mq($from: tablet) {
                padding-left: 0;
                padding-right: 0;
            }
        }

        .toolbar-amount {
            @include mq($from: tablet) {
                position: relative;
            }
        }

        .pages {
            @include mq($from: tablet) {
                float: right;
            }
        }
    }

    .block-order-details {
        &-comments {
            margin: 0 0 40px;

            @include mq($from: tablet) {
                margin: 0 0 60px;
            }

            .comment-date {
                font-weight: bold;

                @include mq($from: tablet) {
                    clear: left;
                    float: left;
                    margin-right: 50px;
                    max-width: 90px;
                }
            }

            .comment-content {
                line-height: 1.6;
                margin: 0 0 20px;

                @include mq($from: tablet) {
                    overflow: hidden;
                }
            }
        }

        &-view {
            .block-content .box {
                margin-bottom: 30px;
            }

            .box-title {
                font-weight: bold;
            }

            .box-content {
                .payment-method {
                    .title {
                        font-weight: normal;
                    }

                    .content {
                        margin: 0;

                        > strong {
                            font-weight: normal;

                            &:after {
                                content: ': ';
                            }
                        }
                    }
                }
            }
        }

        &-gift-message {
            .column.main .order-details-items & {
                margin: 40px 0 0;
            }

            + .actions-toolbar {
                display: none;
            }
        }
    }

    .table-order-items {
        tbody {
            .col {
                &.label,
                &.value {
                    @include mq($from: tablet) {
                        padding-left: 0;
                    }
                }
            }
        }

        &.invoice,
        &.shipment {
            .col.qty {
                @include mq($from: tablet) {
                    text-align: center;
                }
            }
        }

        &.creditmemo {
            .col {
                &.qty,
                &.discount,
                &.subtotal {
                    @include mq($from: tablet) {
                        text-align: center;
                    }
                }

                &.total {
                    @include mq($from: tablet) {
                        text-align: right;
                    }
                }
            }
        }
    }
}
