//
// Generic Form styles
//

$styled-form-max-width: 640px;

form {
  .fieldset {
    &.hidden-fields {
      display: none;
    }
  }

  .field {
    position: relative;

    &:not(.search) {
      margin-bottom: pinpoint-spacing(small);
    }

    &.required {
      .label::after {
        content: "*";
      }
    }

    .input-text {
      &.mage-error {
        border: 1px solid $color-alert;
      }
      &.valid {
        border: 1px solid $color-success;
      }
    }
  }

  .input-text,
  .select,
  .textarea {
    width: 100%;
  }

  .mage-error {
    color: $color-alert;
  }

  .actions-toolbar:not(.login .actions-toolbar) {
    display: flex;
    gap: pinpoint-spacing(small);
    margin-bottom: pinpoint-spacing(small)
  }
}

.styled-form {
  max-width: $styled-form-max-width;
  border: solid 1px $fieldBorder;
  border-radius: $global-radius;
  padding: pinpoint-spacing(base);
  margin: pinpoint-spacing(base) auto 0;
  @include mq($until: tablet) {
    margin-left: pinpoint-spacing(base);
    margin-right: pinpoint-spacing(base);
  }
}

@import "forms__extend";
