/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/**
 * 1. Ensure the page-wrapper always fills at least the entire height of the viewport.
 * 2. Ensure the space between the header and footer is flexible and will always fill
 *    remaining space.
 */

$constrained-width: 1920px !default;
$sidebar-width: 280px !default;
$pdp-column-main-gap: pinpoint-spacing(base) !default;
$pdp-column-main-justify-content: center !default;

body {
  .page-wrapper {
    min-height: 100%; /* [1] */
    height: 100%; /* [1] */
    display: flex;
    flex-direction: column;

    > * {
      flex: 0; /* [2] */
    }

    [class^="page-main"] {
      flex: 1; /* [2] */
    }
    
    .page-main {
      width: 100%;
      @include pinpoint-constrained-widths();
    }
  }

  .columns {
    display: grid;
    gap: pinpoint-spacing(base);
    grid-template-rows: auto;
  }

  @include mq($from: desktop) {
    &.page-layout-1column {
      --constrained-width: #{$constrained-width};
      --constrained-margin: auto;

      .columns {
        grid-template-columns: auto;

        .column.main {
          grid-column: 1;
          grid-row: 1;
        }
      }
    }

    &.page-layout-2columns-left {
      --constrained-width: #{$constrained-width};
      --constrained-margin: auto;

      .columns {
        grid-template-columns: $sidebar-width auto;

        .column.main {
          grid-column: 2;
          grid-row: 1;
        }

        .sidebar-wrapper {
          grid-column: 1;
          grid-row: 1;
        }
      }
    }

    &.page-layout-2columns-right {
      --constrained-width: #{$constrained-width};
      --constrained-margin: auto;

      .columns {
        grid-template-columns: auto $sidebar-width;

        .column.main {
          grid-column: 1;
          grid-row: 1;
        }

        .sidebar-wrapper {
          grid-column: 2;
          grid-row: 1;
        }
      }
    }

    &.page-layout-3columns {
      --constrained-width: #{$constrained-width};
      --constrained-margin: auto;

      .columns {
        grid-template-columns: $sidebar-width auto $sidebar-width;

        .column.main {
          grid-column: 2;
          grid-row: 1;
        }

        .sidebar-main {
          grid-column: 1;
          grid-row: 1;
        }

        .sidebar-additional {
          grid-column: 3;
          grid-row: 1;
        }
      }
    }
  }

  // PDP Specific Layout Rules
  &.catalog-product-view {
    .column.main {
      display: flex;
      flex-direction: column;

      @include mq($from: desktop) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: $pdp-column-main-justify-content;
        gap: $pdp-column-main-gap;
      }

      > * {
        flex: 1 1 100%;
      }

      @include mq($from: desktop) {
        .product-info-main {
          flex: 1 1 auto; // Needed to place alongside image gallery on desktop
        }
      }

      .media {
        order: -1; // Forces image to come before product-info-main
      }
    }
  }

  // Account Area Specific Layout Rules
  &.account {
    .columns {
      padding: 0 pinpoint-spacing(base)
    }
  }
}

@import "layout__extend";
