@include mq($from: tablet) {
    .message.cookie {
        .content {
            display: flex;
            align-items: center;

            .actions {
                margin: 0 0 0 12px !important; // I must use !important as the initial component is styled by ID
                // TODO: discuss styling by ID issue with the team
            }
        }
    }
}
