$main-menu-color: $color-white !default;
$main-menu-background-color: $color-primary !default;
$main-menu-background-color--desktop: $color-primary !default;
$main-menu-padding: 0 !default;
$main-menu-margin: 0 !default;
$main-menu-position: relative !default;

$main-menu-list-position-top: 0 !default;
$main-menu-list-position-left: 0 !default;
$main-menu-list-position-right: 0 !default;
$main-menu-list-justify-content: space-between !default;

$main-menu-item-background-color: lighten($main-menu-background-color, 10) !default;
$main-menu-item-border: 1px solid darken($main-menu-background-color, 10%) !default;
$main-menu-item-padding: 0 !default;

$main-menu-link-color: $color-white !default;
$main-menu-link-color--desktop: $color-white !default;
$main-menu-link-color-hover: fade-out($main-menu-link-color, 0.4) !default;
$main-menu-link-color-hover--desktop: fade-out($main-menu-link-color, 0.4) !default;
$main-menu-inner-link-color: $color-white !default;
$main-menu-inner-parent-link-color: $color-white !default;


$main-menu-inner-list-background-color: lighten($main-menu-background-color, 10) !default;

$main-menu-inner-item-border: 1px solid darken($main-menu-item-background-color, 10%) !default;

$main-menu-submenu-open-icon-color: $color-white !default;
$main-menu-submenu-open-icon: "../images/icons/icon-chevron-right.svg" !default;

.main-menu {
  color: $main-menu-color;
  background: $main-menu-background-color;
  padding: $main-menu-padding;
  margin: $main-menu-margin;
  display: none;
  position: $main-menu-position;

  &.active {
    display: block;
  }

  @include mq($from: desktop) {
    display: block;
    background-color: $main-menu-background-color--desktop;
  }


  // TOP LEVEL LIST

  &__list {
    display: flex;
    flex-direction: column;

    @include mq($until: desktop) {
      position: absolute;
      top: $main-menu-list-position-top;
      left: $main-menu-list-position-left;
      right: $main-menu-list-position-right;
      height: 100vh;
      background: $main-menu-background-color;
      overflow-x: hidden;
      z-index: 10;
    }

    @include mq($from: desktop) {
      flex-direction: row;
      justify-content: $main-menu-list-justify-content;
      align-items: stretch;
      @include pinpoint-constrained-widths();
    }
  }


  // TOP LEVEL MENU ITEMS

  &__item {
    @include mq($until: desktop) {
      border-bottom: $main-menu-item-border;
    }

    @include mq($from: desktop) {
      // flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding: $main-menu-item-padding;

      &:hover {
        & > .main-menu__inner-list {
          display: grid;
        }
      }
    }
  }


  // TOP LEVEL MENU LINKS

  &__link {
    display: block;
    padding: pinpoint-spacing(small);
    color: $main-menu-link-color;
    
    &:hover {
      color: $main-menu-link-color-hover;
      text-decoration: none;
    }
    
    @include mq($until: desktop) {
      flex: 1;
    }
    
    @include mq($from: desktop) {
      color: $main-menu-link-color--desktop;

      &:hover {
        color: $main-menu-link-color-hover--desktop;
      }
    }
  }




  // INNER MENU LISTS

  &__inner-list {
    background: $main-menu-inner-list-background-color;
    z-index: 10;
    
    @include mq($until: desktop) {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      height: 100vh;
      overflow-x: hidden;
      transform: translateX(100%);
      transition: transform 1s;
      
      &.active {
        transform: translateX(0%);
      }
    }
    
    @include mq($from: desktop) {

      &--level1 {
        display: none;
        grid-template-columns: repeat(6, 1fr);
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: pinpoint-spacing(small);
        text-align: left;
        z-index: 9;
        box-shadow: 0px 6px 12px 6px rgba(0,0,0,0.1);
        @include pinpoint-constrained-widths();
      }
    }
  }


  // INNER MENU ITEMS

  &__inner-item {
    @include mq($until: desktop) {
      border-bottom: $main-menu-inner-item-border;
    }

    @include mq($from: desktop) {
      &--all {
        display: none;
      }

      &--parent {
        & > .main-menu__inner-link {
          color: $main-menu-inner-parent-link-color;
          font-weight: bold;
          
          &:hover {
            color: fade-out($main-menu-inner-parent-link-color, 0.4);
          }

          &::after {
            content: " >";
          }
        }
      }

      &.push-right {
        grid-column-start: 5;
        grid-column-end: 7;
        grid-row-start: 1;
        justify-self: end;
      }
    }
  }


  // INNER MENU LINKS

  &__inner-link {
    flex: 1;
    display: block;
    padding: pinpoint-spacing(small);
    color: $main-menu-inner-link-color;

    &:hover {
      color: fade-out($main-menu-inner-link-color, 0.4);
      text-decoration: none;
    }
  }



  // OTHER BITS

  @include mq($until: desktop) {
    [class$="--parent"] {
      display: flex;
    }
  }

  div.main-menu__inner-link {
    display: none;
  }

  .submenu-open {
    display: flex;
    align-items: center;
    border-left: $main-menu-item-border;

    &::after {
      content: "";
      width: pinpoint-spacing(large);
      height: pinpoint-spacing(base);
      display: inline-flex;
      background-color: $main-menu-submenu-open-icon-color;
      mask-image: url($main-menu-submenu-open-icon);
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
    }

    @include mq($from: desktop) {
      display: none;
    }
  }

  .submenu-back {
    display: block;
    padding: pinpoint-spacing(small);
    font-weight: bold;
    font-size: small;

    @include mq($from: desktop) {
      display: none;
    }
  }
}

@import "main-menu__extend";
