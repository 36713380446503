// TODO - Delete when base theme modal file is refactored
.modal-header {
    background-color: $color-primary;
}

.modal-footer {
    padding-bottom: pinpoint-spacing(base);

    .action.action-hide-popup {
        color: $color-primary !important;
        background-color: $color-white !important;
        border: 1px solid $color-primary !important;
    }
}

.modal-popup.confirm {
    .action-primary {
        background-color: $color-primary;
        color: $color-white;
    }
    .action-secondary {
        background-color: #ECECEC;
    }
}
