form {

}

fieldset {

}

legend {

}

label {
  cursor: pointer;
}

input:not([type='checkbox'],[type='radio']) {
  height: pinpoint-spacing(large);
  color: $color-black;
  background-color: $color-white;
  border: 1px solid $color-grey;
  border-radius: $global-radius;
  padding: pinpoint-spacing(small);

  &::placeholder {
    opacity: 1;
  }

  &:focus,
  &.selected {
    border: 1px solid $color-primary;
  }
}

input[type="checkbox"] {
  width: pinpoint-spacing(base);
  height: pinpoint-spacing(base);
  background-color: $color-white;
  border: 1px solid $color-black;
  display: inline-grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: pinpoint-spacing(small);
  height: pinpoint-spacing(small);
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $color-primary;
  transform-origin: center;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

input[type="checkbox"]:checked::before {
  transform: scale(1.4);
}

input[type="radio"] {
  width: pinpoint-spacing(base);
  height: pinpoint-spacing(base);
  background-color: $color-white;
  border: 1px solid $color-black;
  border-radius: 50%;
  display: inline-grid;
  place-content: center;
}

input[type="radio"]::before {
  content: "";
  width: pinpoint-spacing(small);
  height: pinpoint-spacing(small);
  border-radius: 50%;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em $color-primary;
  transform-origin: center;
}

input[type="radio"]:checked::before {
  transform: scale(1.4);
}

textarea {
  color: $color-black;
  background-color: $color-white;
  border: 1px solid $color-grey;
  border-radius: $global-radius;
  padding: pinpoint-spacing(small);

  &::placeholder {
    opacity: 1;
  }
}

select {
  display: flex;
  align-items: center;
  height: pinpoint-spacing(large);
  padding: pinpoint-spacing(small) pinpoint-spacing(large) pinpoint-spacing(small) pinpoint-spacing(small);
  color: $color-black;
  background-color: $color-white;
  background-image: url("../images/icons/fa-solid/caret-down.svg");
  background-size: pinpoint-spacing(small);
  background-repeat: no-repeat;
  background-position: calc(100% - pinpoint-spacing(small));
  border: 1px solid $color-grey;
  border-radius: $global-radius;
}

// The following doesn't belong in this file, it will need moving to another file
.field.choice.related {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: pinpoint-spacing(tiny);
}