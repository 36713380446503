$tax-switcher-font-weight: bold !default;
$tax-switcher-background: $color-secondary !default;

.tax.switch {
  background-color: $color-tertiary;
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  align-items: center;
  gap: 8px;
  padding: 0px pinpoint-spacing(base);
  color: $color-white;

  @include mq($from: tablet) {
    padding: 8px 20px;
    margin-left: 32px;
  }
}

#tax_switch {
  width: pinpoint-spacing(large);
  border-radius: 30%/50%;
  background-color: $tax-switcher-background;

  &::before {
    width: 16px;
    height: 16px;
    clip-path: unset;
    transform: translateX(pinpoint-spacing(small));
    border-radius: 50%;
    box-shadow: inset 1em 1em $color-black;
  }

  ~ .slider.label {
    width: 100px;
    text-align: right;
    white-space: nowrap;
    font-weight: $tax-switcher-font-weight;
    letter-spacing: 0.32px;
  }

  ~ .slider.round {
    display: none;
  }

  & ~ .slider.including-tax {
    display: none;
  }

  &:checked {
    background-color: $color-white;

    &::before {
      transform: translateX(-#{pinpoint-spacing(small)});
    }

    & ~ .slider.excluding-tax {
      display: none;
    }

    & ~ .slider.including-tax {
      display: inline;
    }
  }
}

.account.requisition_list-requisition-view .table-wrapper td.col.price span.price {
  content: '';
}

.account.requisition_list-requisition-view .table-wrapper td.col.subtotal span.price {
  display: block;

  &:after {
    content: '';
  }
}


.price-excluding-tax {
  display: none;
}

.price-including-tax::after {
  content: ' INC. VAT';
  @include pinpoint-font-size(12px);
  color: $color-primary;
  white-space: nowrap;
}

.price-excluding-tax::after {
  content: ' EX. VAT';
  @include pinpoint-font-size(12px);
  color: $color-primary;
}

body.excluding-tax {
  .price-including-tax {
    display: none;
  }

  .price-excluding-tax {
    display: inline;
  }
}

// TEMP FIX FOR TAX SWITCHER PRICES, NEEDS REFACTORING IN BASE THEME

.price-container {
  .price-label {
  }

  .price {
    color: $color-primary;
    font-weight: $tax-switcher-font-weight;
  }
}

.main-menu__mobile-tax {
  display: none;

  @include mq($until: desktop) {
    display: block;
    .tax.switch {
      padding: pinpoint-spacing(small);
      justify-content: center;
      max-height: 40px;
      position: fixed;
      bottom: 0;
      width: 100%;
    }
  }

  @include mq($from: tablet) {
    .tax.switch {
      margin: 0;
    }
  }
}
