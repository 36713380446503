/* ==========================================================================
   #SPACINGS
   ========================================================================== */

/**
 * Utility classes to put specific spacing values onto elements. The below loop
 * will generate us a suite of classes like:
 *
 *   .u-margin-top {}
 *   .u-padding-left-large {}
 *   .u-margin-right-small {}
 *   .u-padding {}
 *   .u-padding-right-none {}
 *   .u-padding-horizontal {}
 *   .u-padding-vertical-small {}
 */

$pinpoint-spacing-directions: (
  null: null,
  "-top": "-top",
  "-right": "-right",
  "-bottom": "-bottom",
  "-left": "-left",
  "-horizontal": "-left" "-right",
  "-vertical": "-top" "-bottom",
) !default;

$pinpoint-spacing-properties: (
  "padding": "padding",
  "margin": "margin",
) !default;

$pinpoint-spacing-sizes: (
  null: pinpoint-spacing(base),
  "-tiny": pinpoint-spacing(tiny),
  "-small": pinpoint-spacing(small),
  "-large": pinpoint-spacing(large),
  "-huge": pinpoint-spacing(huge),
  "-none": 0,
) !default;

@each $property-namespace, $property in $pinpoint-spacing-properties {
  @each $direction-namespace, $direction-rules in $pinpoint-spacing-directions {
    @each $size-namespace, $size in $pinpoint-spacing-sizes {
      .u-#{$property-namespace}#{$direction-namespace}#{$size-namespace} {
        @each $direction in $direction-rules {
          #{$property}#{$direction}: $size !important; // sass-lint:disable-line no-important
        }
      }
    }
  }
}
