///* ========================================================================
//   #CONSTRAINED WIDTHS
//   ======================================================================== */

// .usage {
//   @include pinpoint-constrained-widths();
// }

@mixin pinpoint-constrained-widths() {
    max-width: var(--constrained-width);
    margin: var(--constrained-margin);
}
  