$absolute-footer-background-color: #373737 !default;
$absolute-footer-background-color--desktop: $color-grey--dark !default;
$footer-menu-wrapper-label-font-size--desktop: 20px !default;
$footer-menu-wrapper-label-padding-bottom--desktop: 18px !default;
$footer-border-left: $color-secondary !default;

.page-footer {

    .homepage-footer-info img {
        max-width: 250px !important;
        margin: 0 auto 50px;
    }

    .footer-menu {

        .footer-menu__list {
            @include mq($from: desktop) {
                padding: 44px 16px 50px 16px;
                gap: 32px;
            }
        }

        .footer-menu__item {
            @include mq($until: desktop) {
                border-color: $color-grey;
            }
        }

        .footer-menu__wrapper-label {
            font-weight: 500;

            @include mq($until: desktop) {
                padding: 16px 0;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: space-between;
                align-items: center;

                &::after {
                    content: "";
                    width: 24px;
                    height: 24px;
                    display: inline-flex;
                    background-color: #FFF;
                    mask-image: url(../images/icons/icon-chevron.svg);
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;
                }

                &.active {
                    &::after {
                        transform: rotate(180deg);
                    }
                }
            }

            @include mq($from: desktop) {
                font-size: $footer-menu-wrapper-label-font-size--desktop;
                padding-bottom: $footer-menu-wrapper-label-padding-bottom--desktop;
            }
        }

        .footer-menu__inner-list {
            .footer-menu__inner-link {
                padding-left: 0;

                @include mq($from: desktop) {
                    max-width: 250px;
                }
            }
        }
        // Adds left border to a footer menu section (Apply to Newsletter section)
        @include mq($from: desktop) {
            .footer-menu__item--left-border {
                padding-left: 26px;
                border-left: 2px solid $footer-border-left;
            }
        }
    }

    .absolute-footer {
        @include mq($until: desktop) {
            background-color: $absolute-footer-background-color;
        }

        @include mq($from: desktop) {
            background-color: $absolute-footer-background-color--desktop;
        }

        .store-information {
            @include mq($until: desktop) {
                font-size: 12px;
            }
        }

        .store-copyright {
            @include mq($until: desktop) {
                margin-bottom: pinpoint-spacing(base);
            }
        }
    }
}
