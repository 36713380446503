.breadcrumbs {
    background: unset;
    margin-bottom: 40px;

    .items {
        flex-wrap: wrap;
        align-items: center;
        padding: 0 pinpoint-spacing(small);

        .item {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            @include mq($from: desktop) {
                text-wrap: nowrap;
            }

            a,
            strong {
                @include pinpoint-font-size(14px);
            }
        }
    }
}
