/* ==========================================================================
   #PRODUCT PRICE
   ========================================================================== */

.price-box.price-final_price {
   .price-container {
      .price-label {

      }

      .price {
         color: $color-primary;
         font-weight: bold;
      }
   }
}

@import "product-price__extend";
