$search-block-max-width--desktop: 678px !default;
$search-block-content-max-width--desktop: 100% !default;

.action.search::before {
  transform: translateX(-8px);
}

.block-search {
  @include mq($from: desktop) {
    max-width: $search-block-max-width--desktop;
  }

  @include mq($until: desktop) {
    flex: 1 0 100%;
    order: 1;
  }

  .block-content {
    @include mq($from: desktop) {
      max-width: $search-block-content-max-width--desktop;
    }
    .form.minisearch {

      @include mq($until: desktop) {
        position: relative;
      }
      
      .field.search {
        .control {

          .input-text {
            @include mq($until: desktop) {
              padding-left: 40px;
              max-height: 40px;
            }
            
            @include mq($from: desktop) {
              min-height: 56px;
              border-radius: 5px;
              padding-left: 16px;
              padding-right: 40px;
            }
          }
        }
      }

      .actions {
        @include mq($until: desktop) {
          position: absolute;
          left: 0;
          margin-left: 0;

          .action.search::before {
            width: 20px;
            height: 20px;
          }
        }
        @include mq($from: desktop) {
          margin-left: -52px;
          padding-right: 4px;
        }
      }
    }
  }
}

.search.results {
  & > .block {
    display: none;
  }
}


.catalogsearch-result-index {
  .false-title {
    margin-bottom: 0 !important;
  }

  .page-title-wrapper:not(.false-title) {
    .page-title {
      font-size: 18px;
      font-weight: 400;
      justify-content: center;

      &::before,
      &::after {
        all: unset
      }
    }
  }
}