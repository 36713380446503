$increment-button-width: 24px;
$increment-button-height: 24px;
$increment-icon-spacing: 8px;

.field.qty .control,
.control.qty,
.details-qty.qty {
    .quantity-decrease {
        display: block;
        left: pinpoint-spacing(small);
        width: $increment-button-width;
        height: $increment-button-height;

        &::before {
            display: block;
            content: '';
            mask-image: url("../images/icons/icon--minus.svg");
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;
            height: $increment-button-height;
            background-color: $color-black;
            padding: 0 pinpoint-spacing(small);
        }

        &.disabled {
            pointer-events: none;
            touch-action: none;

            &:before {
                background-color: #C7C7C7;
            }
        }
    }
    .quantity-increase {
        display: block;
        right: pinpoint-spacing(small);
        width: $increment-button-width;
        height: $increment-button-height;

        &::after {
            display: block;
            content: '';
            mask-image: url("../images/icons/icon--plus.svg");
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;
            height: $increment-button-height;
            background-color: $color-black;
            padding: 0 pinpoint-spacing(small);
        }
    }
    &.disabled {

        &::before {
            background-color: $border-colour;
        }
    }
}

.field.qty .control {
    height: auto;
    padding: $increment-icon-spacing;

    &.qty {
        width: 120px;
    }

    &:before,
    &:after {
        min-height: $increment-button-height;
    }

    input[type=number] {
        height: auto;
        background: none;
        border: 0;
        padding: 0;
        margin: 0;
    }

    .quantity-decrease {
        left: $increment-icon-spacing;
        top: $increment-icon-spacing;
    }

    .quantity-increase {
        right: $increment-icon-spacing;
        top: $increment-icon-spacing;
    }
}
