$page-title-hide-on-homepage: true !default;
$page-title-color: $color-primary !default;
$page-title-background-color: inherit !default;
$page-title-border-color: $color-primary !default;
$page-title-font-size: 28px !default;
$page-title-font-size--desktop: 36px !default;
$page-title-alignment: left !default;
$page-title-alignment--desktop: left !default;
$page-title-padding: pinpoint-spacing(large) !default;
$page-title-margin-bottom: pinpoint-spacing(small) !default;

.cms-index-index {
  @if $page-title-hide-on-homepage {
    .page-title-wrapper {
      display: none;
    }
  }
}

.page-title-wrapper {
  margin-bottom: $page-title-margin-bottom;

  // Global Page Title Styles
  .page-title {
    color: $page-title-color;
    background-color: $page-title-background-color;
    text-align: $page-title-alignment;
    @include pinpoint-font-size($page-title-font-size);

    @include mq($from: desktop) {
      text-align: $page-title-alignment--desktop;
      @include pinpoint-font-size($page-title-font-size--desktop);
      @include pinpoint-constrained-widths();
    }
  }

  // Page Title Styles for all pages except PDP
  &:not(.product) {
    padding: $page-title-padding;
  }
}


@import "page-title__extend";
