.action:not(.compare) {
    padding: 16px;
}

.action.towishlist, .action.tocompare {
    &::before {
        background-color: $color-grey--dark;
    }
}

.checkout-cart-index,
.purchaseorder-purchaseorder-success {
    .action.continue,
    .action.update {
        background-color: $color-primary;
        color: $color-white;
    }
}

.action.action-secondary,
.action.action-clear {
    border: 1px solid $color-primary;
    color: $color-primary;
    margin: 0 16px 16px 0;
    border-radius: 3px;
    padding: 6px;
    font-size: 14px;
    &:hover {
        background: $color-primary;
        color: $color-white;
    }
}

.edit-company-profile {
    background-color: $color-primary;
    color: $color-white;
    border-radius: 3px;
    padding: 13px 24px;
    font-size: 14px;
    min-width: 160px;
}

.action.continue,
.action.update,
.action.delete {

    &:hover {
        background-color: $color-primary-hover;
    }
}
