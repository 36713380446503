// _actions.scss
// Edit existing actions
$action-search: "search" null $color-black "icon--search.svg" before true;
$action-showcart: "showcart" null $color-white "icon--basket.svg" above mobile;
$action-tocart: "tocart" null $color-white;
$action-nav-toggle: "nav-toggle" null $color-white "icon-menu.svg" before true;
$action-nav-toggle-close: "nav-toggle.active" null $color-white "icon-menu-close.svg" before true;
$action-towishlist: "towishlist" $color-white $color-grey "icon-wishlist.svg" before true;
$action-towishlistsplit: "label.action.split" $color-white $color-grey "icon-wishlist.svg" before true;
$action-towishlistsplittoggle: "action.toggle.change:not(.requisition-list-button)" $color-white $color-grey "arrow-down.svg" before true;
$action-tocompare: "tocompare" $color-white $color-grey "icon-compare.svg" before true;
$action-sorter-ascending: "sort-asc" $color-primary $color-white "fa-solid/arrow-up.svg" before true;
$action-sorter-descending: "sort-desc" $color-primary $color-white "fa-solid/arrow-down.svg" before true;
$action-edit: "edit" $color-primary $color-white "fa-solid/pen.svg" before;
$action-delete: "delete" $color-primary $color-white "fa-solid/trash.svg" before true;
$action-viewcart: "viewcart" $color-grey--light $color-grey--dark;
$action-clear: "clear" $color-primary $color-white "fa-solid/trash.svg" before;
$action-multicheckout: "multicheckout" $color-grey $color-white;
$action-delete-cart: "action-delete" $color-primary $color-white "fa-solid/trash.svg" before true;
$action-edit-cart: "action-edit" $color-primary $color-white "fa-solid/pen.svg" before true;

// New actions for theme
$action-store-locator: "store-locator" null $header-icon-links-color "icon--store-locator.svg" above mobile;
$action-my-account: "my-account" null $header-icon-links-color "icon--my-account.svg" above mobile;
$action-quote-list: "quote-list" null $header-icon-links-color "icon--quote-list.svg" above mobile;
$action-requisition-list: "requisition-list-button.toggle.change" $color-secondary $color-black "icon--favourites.svg" before false;

// Payments and social icons (May move these to base theme)
$action-facebook: "facebook" $color-white $color-grey--dark "social/facebook.svg" above true;
$action-twitter: "twitter" $color-white $color-grey--dark "social/x.svg" above true;
$action-instagram: "instagram" $color-white $color-grey--dark "social/instagram.svg" above true;
$action-linkedin: "linkedin" $color-white $color-grey--dark "social/linkedin.svg" above true;
$action-youtube: "youtube" $color-white $color-grey--dark "social/youtube.svg" above true;
$action-pinterest: "pinterest" $color-white $color-grey--dark "fa-brands/pinterest.svg" above true;

$parent-actions-list: $action-store-locator,
$action-my-account,
$action-quote-list,
$action-facebook,
$action-twitter,
$action-instagram,
$action-linkedin,
$action-youtube,
$action-pinterest,
$action-requisition-list;

// _quantity-selector.scss
$quantity-selector-border-color: $fieldBorder;
$quantity-selector-plus-content: "";
$quantity-selector-minus-content: "";

// _page-title.scss
$page-title-padding: pinpoint-spacing(small);
$page-title-font-size--desktop: 30px;

// _checkout-addresses
$new-address-popup-button-background-color: $color-primary;

// _modals.scss
$modal-content-padding: pinpoint-spacing(base);

$counter-number-position-top: -10px;
$counter-number-position-right: 16px;

$color-primary-hover: darken($color-primary, 10%);

@import 'child-theme.variables';
