$footer-menu-color: $color-white !default;
$footer-menu-link-color: $color-white !default;
$footer-menu-background-color: $color-secondary !default;
$footer-menu-background-color--desktop: $color-secondary !default;
$footer-menu-border-color: $color-white !default;

.footer-menu {
  background-color: $footer-menu-background-color;
  color: $footer-menu-color;
  
  @include mq($from: desktop) {
    background-color: $footer-menu-background-color--desktop;
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: 16px;
    padding-top: 24px;
    @include pinpoint-constrained-widths();


    @include mq($from: desktop) {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: pinpoint-spacing(large);
      padding: pinpoint-spacing(large) pinpoint-spacing(base);
    }
  }

  &__item {
    @include mq($until: desktop) {
      border-top: 1px solid $footer-menu-border-color;

      &:last-of-type {
        border-bottom: 1px solid $footer-menu-border-color;
      }
    }

    @include mq($from: desktop) {
      &--flex-1 {
        flex: 1;
      }

      &--flex-2 {
        flex: 2;
      }

      &--flex-3 {
        flex: 3;
      }
    }
  }

  &__wrapper-label {
    font-weight: bold;
    padding: pinpoint-spacing(small) 0 18px 0;

    @include mq($until: desktop) {
      &.active + .footer-menu__inner-list {
        display: block;
      }
    }
  }

  &__inner-list {
    @include mq($until: desktop) {
      display: none;
    }

    @include mq($from: desktop) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }

  &__inner-link {
    display: block;
    color: $footer-menu-link-color;
    padding: 8px 0 8px 0;

    &:hover {
      color: fade-out($footer-menu-link-color, 0.4);
      text-decoration: none;
    }
  }
}

@import "footer__extend";