//
//  Slick slider styles
//  _____________________________________________

.slick-slider {
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-touch-action: pan-y;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    box-sizing: border-box;
    display: block;
    position: relative;
    touch-action: pan-y;
    user-select: none;
}
.slick-list {
    display: block;
    margin: 0;
    overflow: hidden;
    padding: 0;
    position: relative;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-track {
    display: block;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    top: 0;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        border: 1px solid transparent;
        display: block;
        height: auto;
    }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-prev {
    left: 2rem;
    &:before {
        background-image: url("../images/icons/icon-chevron-right.svg");
        background-size: 40px 40px;
        display: inline-block;
        width: 40px;
        height: 40px;
        content: "";
        transform: rotate(180deg);
    }
}

.slick-next {
    right: 2rem;
    &:before {
        background-image: url("../images/icons/icon-chevron-right.svg");
        background-size: 40px 40px;
        display: inline-block;
        width: 40px;
        height: 40px;
        content: "";
    }
}

.slick-prev,
.slick-next {
    -ms-transform: translate(0, -50%);
    -webkit-transform: translate(0, -50%);
    background: rgba(252, 252, 252, .6);
    border: none;
    border-radius: 0;
    color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0;
    height: 60px;
    line-height: 0;
    outline: none;
    padding: 0;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    width: 60px;
    z-index: 101;

    &.slick-disabled {
        opacity: .2;
    }

    &:hover {
        background: rgba(252, 252, 252, 1);
        border: none;
        &.slick-disabled {
            opacity: .2;
        }
        &:before {
            color: $color-secondary;
        }
    }

    &:before,
    &:after {
        color: $color-secondary;
        font-size: 38px;
        opacity: 1;
    }

    &:active,
    &:focus,
    &:not(.primary) {
        background: rgba(252, 252, 252, .7);
        border: none;
        box-shadow: none;

        &:active {
            box-shadow: none;
        }
    }
}

.slick-dots {
    display: block;
    list-style: none;
    margin: 0;
    padding: 15px 0;
    text-align: center;
    width: 100%;
    li {
        cursor: pointer;
        display: inline-block;
        height: 14px;
        margin: 0 5px;
        padding: 0;
        position: relative;
        vertical-align: middle;
        width: 14px;
        button {
            background: $color-white;
            border-radius: 10px;
            box-shadow: none;
            cursor: pointer;
            display: block;
            height: 14px;
            padding: 0;
            text-indent: -99999px;
            transition: .3s;
            width: 14px;

            &:hover {
                border: 1px solid $border;
            }

            &:active,
            &:focus,
            &:not(.primary) {
                box-shadow: none;
            }
        }
    }
}

@import "slick__extend";
