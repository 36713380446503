/* ==========================================================================
   # COOKIE NOTICE
   ========================================================================== */

$cookie-notice-text-color: $color-white !default;
$cookie-notice-background: $color-primary !default;
$cookie-notice-block-background: $color-grey--light !default;
$cookie-notice-padding: pinpoint-spacing(small) !default;


// Cookies disabled

#cookie-status {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: $cookie-notice-padding;
  color: $cookie-notice-text-color;
  background-color: $cookie-notice-background;
  border-bottom: 1px solid darken($cookie-notice-background, 10);

  &::before {
    display: block;
    content: "🍪";
  }
}

// Cookie consent

#notice-cookie-block {
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: pinpoint-spacing(base);
  background-color: $cookie-notice-block-background;
  border-top: solid 4px $color-primary;
  z-index: 10;
  .actions {
    margin-top: pinpoint-spacing(small);
  }
}

@import "cookie-notice__extend";
