/* ==========================================================================
   # PAGINATION
   ========================================================================== */

$pagination-link-color: $color-white !default;
$pagination-background-color: $color-primary !default;
$pagination-border-color: $color-secondary !default;
$pagination-border-radius: null !default;
$pagination-padding: pinpoint-spacing(small) !default;
$pagination-item-width: pinpoint-spacing(large) !default;
$pagination-current-item-color: $color-white !default;
$pagination-current-item-background-color: $color-secondary !default;

.pages {
  flex: 1;

  &-label {
    display: none;
  }

  &-items {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: pinpoint-spacing(tiny);

    @include mq($from: tablet) {
      justify-content: flex-start;
    }
  }

  .item {
    .label {
        display: none
    }

    .page {
      color: $pagination-link-color;
      background-color: $pagination-background-color;
      border: 1px solid $pagination-border-color;
      padding: $pagination-padding;
      border-radius: $pagination-border-radius;
      width: $pagination-item-width;
    }

    &.current {
      .page {
        color: $pagination-current-item-color;
        background-color: $pagination-current-item-background-color;
      }
    }

    &.pages-item-previous,
    &.pages-item-next {
      .action {
        color: $pagination-link-color;

        &:hover {
          color: fade-out($pagination-link-color, 0.4);
        }
      }
    }
  }
}

@import "pagination__extend";
