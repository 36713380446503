$newsletter-color: inherit !default;
$newsletter-background-color: $color-grey--light !default;
$newsletter-padding: pinpoint-spacing(base) pinpoint-spacing(small) !default;
$newsletter-padding--tablet: pinpoint-spacing(large) pinpoint-spacing(base) !default;
$newsletter-button-width: 85px !default;

.block.newsletter {
  color: $newsletter-color;
  background-color: $newsletter-background-color;
  padding: $newsletter-padding;

  @include mq($from: tablet) {
    padding: $newsletter-padding--tablet;
  }

  .title {
    @include pinpoint-hidden-visually();
  }

  .content {
    @include pinpoint-constrained-widths();
  }

  .form.subscribe {
    display: flex;
    align-items: flex-end;
  }

  .field.newsletter {
    width: calc(100% - #{$newsletter-button-width});
    margin-bottom: 0;
  }

  input[type=email] {
    width: 100%;
  }

  .actions {
    margin-left: -1rem;
    z-index: 1;
  }

  div.mage-error {
    position: absolute;
  }
}

@import "newsletter__extend";
