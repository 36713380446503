.block {
  &.related,
  &.upsell,
  &.crosssell,
  &-viewed-products-grid {
    .block-title {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      gap: pinpoint-spacing(small);
      margin-bottom: pinpoint-spacing(small);

      @include mq($from: desktop) {
        flex-direction: row;
        align-items: center;
      }

      strong {
        flex: 1 0 auto;
      }

      &::after {
        content: "";
        background-color: $page-title-border-color;
        height: 2px;
        width: 100%;
      }
    }
  }
}

@import "block-title__extend";
