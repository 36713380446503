/* ==========================================================================
   # BREADCRUMBS
   ========================================================================== */

$breadcrumbs-text-color: $color-secondary !default;
$breadcrumbs-link-color: $color-secondary !default;
$breadcrumbs-background: $color-grey--light !default;
$breadcrumbs-padding--mobile: pinpoint-spacing(tiny) pinpoint-spacing(small) !default;
$breadcrumbs-padding--desktop: pinpoint-spacing(small) !default;

$breadcrumbs-item-pseudo-content: ">" !default;
$breadcrumbs-item-pseudo-padding: 0 pinpoint-spacing(tiny) !default;

.breadcrumbs {
  color: $breadcrumbs-text-color;
  background: $breadcrumbs-background;
  padding: $breadcrumbs-padding--mobile;
  @include pinpoint-font-size(12px);

  @include mq($from: desktop) {
    padding: $breadcrumbs-padding--desktop;
  }

  .items {
    display: flex;
    flex-direction: row;
    align-items: center;
    @include pinpoint-constrained-widths();
  }

  .item {
    &::after {
      content: $breadcrumbs-item-pseudo-content;
      padding: $breadcrumbs-item-pseudo-padding;
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    a {
      color: $breadcrumbs-link-color;
      text-decoration: none;
    }
  }
}

@import "breadcrumbs__extend";
