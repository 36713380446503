$modal-header-background: $color-primary !default;
$modal-header-close-icon: "../images/icons/icon-close-white.svg" !default;
$modal-content-padding: 0 !default;

//
// START
// Do not remove or override this block
// JS will not remove the _has-modal class from the body if removed.
// Modals will then fail after first use.
//

.modal-slide .modal-inner-wrap,
.modal-popup .modal-inner-wrap {
  opacity: 1;
}

//
// END
//

.modal-slide,
.modal-popup {
  width: 100%; // no !important here
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  padding: 0;
  bottom: 0;
  // transform: translateX(100%); - not sure if this is needed still. Might be an IE11 thing.
  transform: translate3d(100%, 0, 0);
  transition: transform 600ms ease;
  z-index: 1010;
  min-height: 100vh;
  height: 100vh;
  background: $color-white;
  outline: none;

  //@include breakpoint(500px) {
  max-width: 480px; // so its never wider than the mobile design
  //}
}

.modal-slide._show,
.modal-popup._show {
  width: 100% !important; // sass-lint:disable-line no-important
  // transform: translateX(0);
  transform: translate3d(0, 0, 0);
  overflow: auto;
  visibility: visible !important; // sass-lint:disable-line no-important
  opacity: 1 !important; // sass-lint:disable-line no-important
}

.modal-slide._noshow,
.modal-popup._noshow {
  width: 0; // no !important here
  visibility: hidden;
  opacity: 0;
}

.modals-overlay {
  background: rgba(0, 0, 0, .4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.modal-header {
  background: $modal-header-background;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;

  .modal-title {
    @include pinpoint-font-size(16px);
    font-weight: 600;
    display: inline-block;
    line-height: 64px;
    height: 64px;
    color: $color-white;
    margin-bottom: 0;
    margin-top: 0;
  }

  .action-close {
    background: url($modal-header-close-icon) 50% 50% no-repeat;
    background-size: 16px;
    align-self: center;
    padding: pinpoint-spacing(base);

    span {
      @include pinpoint-hidden-visually();
    }
  }
}

.modal-content {
  padding: $modal-content-padding;
}

.modal-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  padding: 0 24px;

  .action.secondary {
    margin-left: 16px;
  }
}

.modal-popup.confirm {

  &._show {

    @include mq($from: desktop) {
      min-height: auto;
    }
  }

  & + .modals-overlay {
    z-index: 1195 !important; // sass-lint:disable-line no-important
  }

  .modal-header {

    .action-close {
      margin-left: auto;
    }
  }

  .modal-content {
    padding: 16px;
  }

  .modal-footer {
    @include mq($from: desktop) {
      padding: 0 32px;
    }
  }

  .action-primary {
    order: 1;
    margin-right: 16px;
  }

  .action-secondary {
    order: 2;
  }
}

@import "modal__extend";
