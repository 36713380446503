///*==========================================================================
//  #COLOR
//  ========================================================================== */

$color-primary: #48c0be !default;
$color-secondary: #464f5a !default;
$color-tertiary: #234d81 !default;
$color-cta: #03a103 !default;

$color-black: #000 !default;
$color-white: #fff !default;
$color-grey: #929eaa !default;
$color-grey--light: #F4F6F8 !default;
$color-grey--dark: #647380 !default;

$color-alert: #ca1010 !default;
$color-warning: #e4cf18 !default;
$color-success: #15920a !default;
$color-info: #2d88f0 !default;

$color-palette: (
  // Main Colors
  primary: $color-primary,
  secondary: $color-secondary,
  cta: $color-cta
) !default;

$alert-colors: (
  // Alert Colors
  alert: $color-alert,
  warning: $color-warning,
  success: $color-success,
  info: $color-info
) !default;

//
// Original colours from spirit... all the below should be removed
//

$accent: #e0f5f5 !default;

$fieldBorder: #c4cdd5 !default;
$fieldSelected: #47C1BF !default;
$fieldErrorShadow: #FBEAE6 !default;

$border: #dfe3e8 !default;


$textLight: #647380 !default;
$shade: #F4F6F8 !default;
$buttonBorder: #C4CDD5 !default;
$blockBg: #F9FAFB !default;
$inputText: #929EAA !default;
$error: #DB381A !default;

$errorBg: #fbeae6 !default;
$warningBg: #fff9a5 !default;
$noticeBg: #e0f5f5 !default;
$successBg: $color-primary !default;
