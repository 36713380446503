$absolute-footer-background-color: $color-grey--dark !default;

.absolute-footer {
  background-color: $color-grey--dark;

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: pinpoint-spacing(base);
    align-items: center;
    color: $color-white;
    padding: 16px;
    @include pinpoint-constrained-widths();

    @include mq($from: desktop) {
      flex-direction: row;
    }

      .store-information {
          text-align: center;
      }
  }
}
