@each $name, $color in $color-palette {
  .u-color-#{$name} {
    color: $color !important; // sass-lint:disable-line no-important
  }
}

@each $name, $color in $color-palette {
  .u-bg-color-#{$name} {
    background-color: $color !important; // sass-lint:disable-line no-important
  }
}
