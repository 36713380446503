$usp-bar-font-weight--desktop: bold !default;
$usp-bar-homepage-margin: 0 !default;

.cms-home {
  .header-usp-bar {
    margin-bottom: $usp-bar-homepage-margin;
  }
}

.header-usp-bar {
    box-shadow: rgb(189 189 189 / 36%) 0 25px 20px -20px;
    margin-bottom: pinpoint-spacing(base);
}

[data-content-type="pinpoint_pagebuilderuspbar"]:not(.product-info-main [data-content-type="pinpoint_pagebuilderuspbar"]) {
  container-type: inline-size;
  z-index: 1;

  @include mq($from: tablet) {
    @container (max-width: 876px) {
      [data-content-type="pinpoint_pagebuilderuspitem"]:nth-child(3) {
        display: none;
      }
    }
    @container (max-width: 1143px) {
      [data-content-type="pinpoint_pagebuilderuspitem"]:nth-child(4) {
        display: none;
      }
    }
  }

  @include mq($until: tablet) {
    margin-top: -12px;
    margin-bottom: -12px;
  }

  @include mq($from: tablet) {
    margin-top: -4px;
    margin-bottom: -4px;
  }

  [data-content-type="pinpoint_pagebuilderuspitem"] {
    padding-left: pinpoint-spacing(small);
    padding-right: pinpoint-spacing(small);
    align-items: center;

    @include mq($from: tablet) {
      border-left: solid 1px $fieldBorder;
    }

    &:first-child {
      border-left: 0;
    }

    [data-content-type='pinpoint_pagebuilderuspitemicon'] {
      min-width: 48px;
      min-height: 48px;
    }

    [data-element="usp_icon_element"] {
      &::after {
        width: 48px;
        height: 48px;
      }
    }

    [data-content-type="pinpoint_pagebuilderuspitemtext"] {
      @include mq($until: tablet) {
        margin-bottom: 5px;
      }
    }

    .usp-title-text {
      font-size: 14px;
      line-height: 1.5;

      @include mq($from: tablet) {
        font-size: 16px;
        font-weight: $usp-bar-font-weight--desktop;
      }

        a {
            display: inline;
        }
    }

    .usp-sub-title-text {
      font-size: 12px;
      line-height: 1;

      @include mq($from: tablet) {
        font-size: 14px;
      }
    }
  }
}
