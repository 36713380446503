// _actions.scss

// _header.scss
$panel-background-color: $color-secondary;
$panel-color: $color-black;
$header-background-color: $color-primary;

// _minicart.scss
$minicart-position-top--desktop: 75px;
$minicart-position-right--desktop: 24px;
$minicart-header-background-color: $color-grey--light;
$minicart-header-color: $color-grey--dark;

// _counter.scss
// Border colour could be added to parent theme
$counter-text-color: $color-black;
$counter-background: $color-secondary;

// _main-menu.scss
$main-menu-color: $color-primary;
$main-menu-background-color: $color-white;
$main-menu-background-color--desktop: $color-tertiary;
$main-menu-padding: 0;
$main-menu-margin: 0;

$main-menu-item-background-color: $color-white;

$main-menu-link-color: $color-black;
$main-menu-link-color--desktop: $color-white;
$main-menu-inner-link-color: $color-black;
$main-menu-inner-parent-link-color: $color-primary;

$main-menu-inner-list-background-color: $color-white;

$main-menu-submenu-open-icon-color: $color-primary;

// _breadcrumbs.scss
$breadcrumbs-text-color: $color-black;
$breadcrumbs-link-color: $color-grey--dark;
$breadcrumbs-background: $color-white;
$breadcrumbs-item-pseudo-content: "/";
$breadcrumbs-item-pseudo-padding: pinpoint-spacing(tiny); // TODO - Unable to find pinpoint-spacing from the child theme
$breadcrumbs-padding--desktop: pinpoint-spacing(tiny);


// _header-usps.scss

// TODO - Header USP Section should be added to the parent theme? Colour variables for icons and highlighted text should be added here as well as seprator psudo content

// _page-title.scss
$page-title-color: $color-black;
$page-title-alignment: center;
$page-title-alignment--desktop: left;
$page-title-border-color: $color-secondary;

// _pagination.scss
$pagination-link-color: $color-primary;
$pagination-background-color: $color-white;
$pagination-border-color: $color-primary;

// _product-filters.scss
$product-filter-color: $color-black; // TODO - Update parent theme to add product filter colour variable
$product-filter-checkbox-color: $color-primary; // TODO - Update parent theme to add product filter checkbox color variable
// Suggestion: Add () psudo elemeents around filter product qty - Maybe have these as a variable

// _cookie-notice.scss
//_cookie-disabled-notice.scss
// This is not the modal it's the disabled cookies notice
// icon variable
$cookie-notice-text-color: $color-white;
$cookie-notice-background: $color-primary;

// _newsletter.scss
$newsletter-color: $color-white;
$newsletter-background-color: $color-black;
$newsletter-padding: 0;
$newsletter-padding--tablet: 0;

// _footer.scss
// TODO - Create _footer.scss file in parent theme and add variables
$footer-menu-background-color: $color-black;
$footer-menu-background-color--desktop: $color-black;
$footer-menu-color: $color-white;


// _absolute-footer.scss - will be for bottom bar
$footer-bottom-bar-background-color: $color-grey--dark;
$footer-bottom-bar-color: $color-white;

// _forms.scss
// TODO - Create variables for various form elements in the parent theme, things like input padding, border colour, border-radius and text colour etc..

// _contact-form.scss - might not be needed
$contact-form-bg-color: #f6f6f6; // TODO - Get actual colour
$contact-form-legend-color: $color-black; // Is this variable needed? Try removing this overwrite and looking at the contact page text
$contact-form-legend-color: $color-black; // Is this variable needed? Try removing this overwrite and looking at the contact page text

// _notifications.scss
// TODO - Variables should be created within _notifications.scss - Correct colours need to be added for this theme
$notification-success-background-color: $color-primary;
$notification-success-icon: "";
$notification-error-background-color: #DB381A;
$notification-error-icon: "";
$notification-warning-background-color: #fff9a5;
$notification-warning-icon: "";
$notification-notice-background-color: #e0f5f5;
$notification-notice-icon: "";
$notification-color: $color-white; // This might not been needed - could be done with shades based on background colour

// dropship settings
$dropship-notice-bg: $color-secondary;
$dropship-notice-icon-bg: $color-black;
$dropship-notice-color: $color-black;
