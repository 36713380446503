/* ==========================================================================
   # COUNTER
   ========================================================================== */

$counter-text-color: $color-white !default;
$counter-background: $color-primary !default;
$counter-size: pinpoint-spacing(base) !default;
$counter-font-size: pinpoint-spacing(small) !default;
$counter-number-position-top: 0 !default;
$counter-number-position-right: pinpoint-spacing(tiny) !default;

.minicart-wrapper {

  .counter {
    position: absolute;
    top: $counter-number-position-top;
    right: $counter-number-position-right;

    .counter-number {
      display: inline-block;
      width: $counter-size;
      height: $counter-size;
      border-radius: 50%;
      background: $counter-background;
      color: $counter-text-color;
      font-size: $counter-font-size;
      font-weight: bold;
      text-align: center;
      line-height: $counter-size;
    }

    .counter-label {
      display: none;
    }

    &.empty {
      display: none;
    }
  }
}

@import "counter__extend";
