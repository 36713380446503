// For shifting stuff to the end of a flex container
.u-shift-right {
  margin-left: auto !important; // sass-lint:disable-line no-important
}

.u-shift-left {
  margin-right: auto !important; // sass-lint:disable-line no-important
}

.u-shift-bottom {
  margin-top: auto !important; // sass-lint:disable-line no-important
}
