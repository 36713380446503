/* ==========================================================================
   #TEXT-ALIGN
   ========================================================================== */

/**
 * Utility classes to easily add text transforms:
 *
 *   <p class="u-text-right">ensure this text is right aligned</p>
 *
 */

.u-text-left {
  text-align: left !important; // sass-lint:disable-line no-important
}

.u-text-center {
  text-align: center !important; // sass-lint:disable-line no-important
}

.u-text-right {
  text-align: right !important; // sass-lint:disable-line no-important
}
