/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

// We have all of our heading font sizes defined here. Passing these pixel
// values into our `pinpoint-font-size()` mixin will generate a rem-based
// `font-size` with a pixel fallback, as well as generating a `line-height` that
// will sit on our baseline grid.

$pinpoint-font-size-h1:  36px !default;
$pinpoint-font-size-h2:  28px !default;
$pinpoint-font-size-h3:  24px !default;
$pinpoint-font-size-h4:  20px !default;
$pinpoint-font-size-h5:  18px !default;
$pinpoint-font-size-h6:  16px !default;

$pinpoint-heading-color: $color-secondary !default;
$pinpoint-h1-color: $pinpoint-heading-color !default;
$pinpoint-h2-color: $pinpoint-heading-color !default;
$pinpoint-h3-color: $pinpoint-heading-color !default;
$pinpoint-h4-color: $pinpoint-heading-color !default;
$pinpoint-h5-color: $pinpoint-heading-color !default;
$pinpoint-h6-color: $pinpoint-heading-color !default;

$pinpoint-h1-font-family: $font-family--heading !default;
$pinpoint-h2-font-family: $font-family--heading !default;
$pinpoint-h3-font-family: $font-family--heading !default;
$pinpoint-h4-font-family: $font-family--heading !default;
$pinpoint-h5-font-family: $font-family--heading !default;
$pinpoint-h6-font-family: $font-family--heading !default;

h1 {
  @include pinpoint-font-size($pinpoint-font-size-h1);
  color: $pinpoint-h1-color;
  font-family: $pinpoint-h1-font-family;
}

h2 {
  @include pinpoint-font-size($pinpoint-font-size-h2);
  color: $pinpoint-h2-color;
  font-family: $pinpoint-h2-font-family;
}

h3 {
  @include pinpoint-font-size($pinpoint-font-size-h3);
  color: $pinpoint-h3-color;
  font-family: $pinpoint-h3-font-family;
}

h4 {
  @include pinpoint-font-size($pinpoint-font-size-h4);
  color: $pinpoint-h4-color;
  font-family: $pinpoint-h4-font-family;
}

h5 {
  @include pinpoint-font-size($pinpoint-font-size-h5);
  color: $pinpoint-h5-color;
  font-family: $pinpoint-h5-font-family;
}

h6 {
  @include pinpoint-font-size($pinpoint-font-size-h6);
  color: $pinpoint-h6-color;
  font-family: $pinpoint-h6-font-family;
}