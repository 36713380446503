.ui-datepicker {
    display: none;

    max-width: 320px;
    padding: pinpoint-spacing(small);
    border: 1px solid $color-grey;
    border-radius: $global-radius;
    background-color: $color-white;
    // box-shadow: $global-box-shadow;
    position: relative;
    z-index: 10;

    &-header,
    &-title {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;
        gap: pinpoint-spacing(small);
    }

    &-header {
        margin-bottom: pinpoint-spacing(small);
    }

    &-prev {
        order: 0;
    }
    &-title {
        order: 1;
        flex: 1;
    }
    &-next {
        order: 2;
    }

    &-month,
    &-year {
        flex: 1;
    }

    &-calendar {
        text-align: center;
        border: 1px solid $color-grey;
    }
}