.block.newsletter {
  .form.subscribe {
    padding-top: 8px;
    flex-wrap: wrap;
  }

  .field.radio.trade {
    flex: 1 0 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: pinpoint-spacing(small);
    margin-bottom: 32px;
  }

  .control.radio-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: pinpoint-spacing(small);

    input[type=radio] {
      display: none;

      &:checked + label {
        background-color: $color-primary;
        color: $color-white;
      }
    }

    label {
      display: block;
      border: 2px solid $color-primary;
      border-radius: $global-radius;
      padding: pinpoint-spacing(small) pinpoint-spacing(base);

      &:hover {
        background-color: $color-primary;
        color: $color-white;
      }
    }
  }

  select {
    border-radius: $global-radius;
    background-color: $color-white;
    color: $color-black;
    line-height: 1;
    background-image: url(../images/icons/caret-down--default.svg);
  }

  .field.newsletter {
    @include mq($until: desktop) {
      width: calc(100% - 40px);
      margin-bottom: pinpoint-spacing(base);
    }

    .required.email {
      margin-top: 6px;
      min-height: 56px;

      &::placeholder {
        color: $color-grey;
        opacity: 1; /* Firefox */
      }
    }
  }

  .actions {
    @include mq($until: desktop) {
      margin-bottom: pinpoint-spacing(base);
    }

    .button.action.subscribe.primary {
      position: relative;
      width: 56px;
      height: 56px;

      &::after {
        position: absolute;
        top: 14px;
        right: 14px;
        content: "";
        width: 28px;
        height: 28px;
        display: inline-flex;
        background-color: $color-white;
        mask-image: url(../images/icons/icon-chevron.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        transform: rotate(-90deg);
      }
    }
  }
}
