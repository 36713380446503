$anchor-color: $color-primary !default;
$anchor-color--hover: fade-out($color-primary, 0.4) !default;

a {
  text-decoration: none;
  color: $anchor-color;

  &.hovered,
  &:hover {
    color: $anchor-color--hover;
    text-decoration: underline;
  }
}
