.lawsonsavs-calculator {

    .field,
    .input,
    .button.primary {
        width: 100%;
        margin: 0;
    }

    input[type="radio"] {
        margin-bottom: pinpoint-spacing(small);
    }

    .width-height-radio {
        margin-left: pinpoint-spacing(small);
    }

    .width-height-radio ~ .field-work-area,
    .field-row.field-row-width-height {
        display: none;
    }

    .work-area-radio:checked~.field-work-area {
        display: block;
    }

    .width-height-radio:checked~.field-row-width-height {
        display: flex;
    }

    .field-row {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        gap: pinpoint-spacing(small);
    }

    .calc-results {
        display: flex;
        align-items: center;
        gap: 10px;
        font-weight: bold;
        font-size: 16px;
        line-height: 1;
        padding: 12px 0;
        span {
            font-weight: bold;
            font-size: 24px;
            color: $color-primary;
        }
    }
}

// Calculators Page Styling
.cms-calculators {
    h2,
    h3 {
        color: $color-primary;
    }

    .calculator-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: pinpoint-spacing(base);
        margin-bottom: pinpoint-spacing(base);

        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr;
        }
    }

    .calculator-container {
        border: 1px solid $color-primary;

        h3 {
            padding: pinpoint-spacing(base) pinpoint-spacing(base) 0;
        }
    }
}
