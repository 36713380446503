$minicart-text-color: $color-black !default;
$minicart-counter-text-color: $color-black !default;

.block.block-minicart {
    color: $minicart-text-color;

    @include mq($until: desktop) {
        max-height: 100vh;
        right: -100%;
        border: unset;
    }
    @include mq($from: desktop) {
        top: 125px;
        right: 0 !important; // Resolves position issue when resizing browser / tablet landscape
        max-width: 392px;

        &::before,
        &::after {
            display: none;
        }
    }
}

.panel.sticky {
    .block.block-minicart {
        @include mq($from: desktop) {
            top: 40px;
            right: -5px !important;
        }
    }
}

.minicart-wrapper {
    @include mq($until: desktop) {
        .mage-dropdown-dialog {
            display: block !important; // Added to prevent complicated js overwrite
        }
    }

    .counter.qty {
        @include mq($until: desktop) {
            right: 2px;
        }

        .counter-number {
            width: 17px;
            height: 17px;
            line-height: 17px;
            border: 1px solid $color-black;
            color: $minicart-counter-text-color;
        }
    }

    #btn-minicart-close {
        background-color: $color-grey--light;
        box-shadow: unset;

        &:before {
            background-color: $color-grey--dark;
        }
    }

    .subtitle:not(.empty) {
        display: none;
    }

    .subtitle.empty {
        font-weight: normal;
        padding: pinpoint-spacing(small);
    }

    .minicart-items-wrapper {
        @include mq($until: desktop) {
            order: -2;
            overflow-y: auto;
            height: unset !important;
        }
        @include mq($from: desktop) {
            max-height: 348px;
        }
    }

    .minicart-items .product-item {

        border-bottom: 1px solid $color-grey--light;
        padding-bottom: pinpoint-spacing(small);

        .product-image-container,
        .product-image-wrapper,
        .product-image-photo {
            width: 64px !important;
            height: 64px !important;
        }

        .product-image-photo {
            border: 1px solid $color-grey--light;
        }

        .product-item-name a {
            color: $color-black;
            font-weight: normal;
        }

        & > .product {
            gap: pinpoint-spacing(small);
        }

        .minicart-price {
            .price {
                @include pinpoint-font-size(14px);
            }
        }

        .qty {
            max-width: 135px;

            label {
                display: none;
            }

            input {
                max-width: 60px;
            }

            .update-cart-item {
                display: none !important;
            }
            .quantity-decrease,
            .quantity-increase {
                width: 24px;
                height: 24px;
                top: 12px;
                &::before,
                &::after {
                    height: 24px;
                }
            }
        }
    }

    .action.checkout::before {
        display: none;
    }


    .paypal-express-in-context-mini-cart-container {
        margin-top: pinpoint-spacing(small);
    }
}

.minicart-wrapper {
    .block-minicart {
        @include mq($from: desktop) {
            border-color: $color-grey--light;
            border-radius: 4px;
            box-shadow: 0px 3px 6px $color-grey--light;
        }
    }

    #minicart-content-wrapper {
        .block-title {
            background-color: $color-grey--xlight;
            padding: 16px;

            @include mq($from: desktop) {
                display: none;
            }

            .text {
                font-weight: normal;
            }
        }

        #btn-minicart-close {
            background-color: $color-grey--xlight;
            top: 10px;
            right: pinpoint-spacing(tiny);

            &::before {
                width: 12px;
                height: 12px;
            }
        }

        .block-content {
            padding: 0;
            gap: 0;

            @include mq($until: desktop) {
                height: calc(100vh - 48px);
            }
        }

        .subtotal {
            justify-content: flex-end;
            gap: 4px;
            @include pinpoint-font-size(14px);
        }

        .items-total {
            padding: pinpoint-spacing(small) 16px;
            background-color: $color-grey--xlight;
            @include pinpoint-font-size(14px);

            @include mq($from: desktop) {
                padding: pinpoint-spacing(small) 20px;
            }

            .count {
                font-weight: bold;
            }
        }

        .subtotal {
            padding: pinpoint-spacing(small) 16px;
            margin-top: -#{pinpoint-spacing(large)};
            @include mq($from: desktop) {
                padding: pinpoint-spacing(small) 20px;
            }

            .price {
                color: $color-black;
            }

            .price-including-tax,
            .price-excluding-tax {
                &::after {
                    color: $color-black;
                    font-weight: bold;
                }
            }
        }

        .actions:not(.product) > .primary {
            padding: 0 16px;
            background-color: $color-grey--xlight;

            @include mq($from: desktop) {
                padding: 0 20px
            }
        }

        .actions:not(.product) > .secondary {
            padding: 0 16px;

            @include mq($until: desktop) {
                background-color: $color-grey--xlight;
            }

            @include mq($from: desktop) {
                padding: 20px 20px 0;
                border: 1px solid $color-grey--xlight;
            }
        }

        .product-item {
            padding: 16px;

            @include mq($from: desktop) {
                padding: 20px;
            }

            .price,
            .price-tax_switch_price::after {
                color: $color-black;
            }

            .product-item-details {

                .product.options {
                    font-size: 14px;

                    .toggle {
                        color: $color-primary;

                        &::after {
                            content: "";
                            width: pinpoint-spacing(small);
                            height: pinpoint-spacing(small);
                            display: inline-flex;
                            background-color: $color-primary;
                            mask-image: url("../images/icons/fa-solid/angle-down.svg");
                            mask-size: contain;
                            mask-repeat: no-repeat;
                            mask-position: center;
                            padding-left: 20px;
                            }
                    }

                    &.active {
                        .toggle {
                            &::after {
                                mask-image: url("../images/icons/fa-solid/angle-up.svg");
                            }
                        }
                    }

                    .content {
                        color: $color-grey;
                    }

                    &.list {
                        display: inline;

                        .label,
                        .values {
                            display: inline;
                        }
                    }
                }

                .product-item-pricing {
                    flex: 0 1 auto;

                    position: relative;

                    &:is(.ajax-loading .product-item-pricing) {

                        &::before {
                            display: block;
                            content: "Updating...";
                            position: absolute;
                            top: 50px;
                            right: 105%;
                            color: $color-black;
                            @include pinpoint-font-size(12px);
                        }

                    }
                }

                .price-container {
                    margin-bottom: 6px;
                }

                .product.actions {
                    flex: 1 1 80px;
                    justify-content: flex-start;
                    margin-bottom: 14px;

                    .primary {
                        display: none;
                    }

                    .action.delete {
                        color: $color-primary;
                        @include pinpoint-font-size(12px);
                        text-decoration: underline;
                        background-color: unset;
                        box-shadow: unset;
                        padding: unset;


                        &::before {
                            display: none;
                        }

                        span {
                            border: inherit !important;
                            clip: inherit !important;
                            -webkit-clip-path: inherit !important;
                            clip-path: inherit !important;
                            height: inherit !important;
                            margin: inherit !important;
                            overflow: inherit !important;
                            padding: inherit !important;
                            position: inherit !important;
                            white-space: inherit !important;
                            width: inherit !important;
                        }
                    }
                }
            }
        }

        .action.primary.checkout {
            margin-bottom: pinpoint-spacing(small);
            @include pinpoint-font-size(20px);
            @include mq($from: desktop) {
                margin-bottom: 20px;
            }
        }

        .action.viewcart {
            margin-bottom: 20px;
            @include mq($until: desktop) {
                margin-bottom: pinpoint-spacing(base);
                background-color: $color-white;
                max-height: 35px;
            }
            @include mq($from: desktop) {
                max-height: 39px;
            }
        }
    }
}
