///* ========================================================================
//   #GLOBAL
//   ======================================================================== */

// The global settings file contains any project-wide variables; things that
// need to be made available to the entire codebase.

// Standardise some UI treatments.
$global-radius: 3px !default;
$global-transition: all 300ms ease-in-out !default;

// Sass-MQ Breakpoints
$mq-breakpoints: (
  mobile: 320px,
  tablet: 768px,
  desktop: 1200px,
) !default;

$mq-show-breakpoints: ();
@if (theme-config(debug) == true) {
  $mq-show-breakpoints: (mobile, tablet, desktop);
}

$font-family--heading: "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-family--body: "Helvetica Neue", Helvetica, Arial, sans-serif !default;

$page-background-color: $color-white !default;