$logo-flex: 1 !default;
$logo-max-width: 320px !default;
$logo-width: auto !default;

$logo-img-width: 100% !default;
$logo-img-height: auto !default;


.logo {
  flex: $logo-flex;
  max-width: $logo-max-width;
  width: $logo-width;
  
  img {
    width: $logo-img-width;
    height: $logo-img-height;
  }
}

@import "logo__extend";
