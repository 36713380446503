@media print {
  @page {size: landscape}

  .page-header,
  .header-usp-bar,
  .page-footer,
  .hidden-print {
    display: none !important;
  }

  a[href]:after {
    content: "";
    display: none;
  }

  abbr[title]:after {
    content: "";
    display: none;
  }

  thead {
    display: table-header-group;
  }

  tr,
  img {
    page-break-inside: avoid;
  }
}

@import "print__extend";
