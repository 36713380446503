$search-input-text-border-radius: $global-radius !default;

.block-search {
  flex: 1 0 100%;
  
  @include mq($from: tablet) {
    flex: 1;
  }
  .block-title {
    display: none
  }

  .block-content {
    .form.minisearch {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: row;
      align-items: center;
      
      .field.search {
        flex: 1;

        .label {
          @include pinpoint-hidden-visually();
        }

        .control {
          .input-text {
            width: 100%;
            border-radius: $search-input-text-border-radius;
          }

          .nested {
            display: none;
          }
        }
      }

      .actions {
        margin-left: -3rem;
        z-index: 9;
      }
    }
  }

  .search-autocomplete {
    display: none;
    background-color: $color-white;
    border: 1px solid $color-grey;
    padding: pinpoint-spacing(tiny);
    z-index: 9;
    margin-top: -#{pinpoint-spacing(tiny)};
    border-top: none;
    border-radius: 0 0 $global-radius $global-radius;

    ul {
      border-top: 1px solid $color-grey--light;
      padding-top: pinpoint-spacing(tiny);
    }

    li {
      display: flex;
      justify-content: space-between;
      cursor: pointer;
    }

    .amount {
      &::before {
        content: "[";
      }
  
      &::after {
        content: "]";
      }
    }
  }
}

@import "search__extend";
