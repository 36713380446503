$panel-color: $color-white !default;
$panel-background-color: $color-secondary !default;
$panel-padding: pinpoint-spacing(tiny) !default;
$panel-margin: 0 !default;

$header-color: inherit !default;
$header-background-color: inherit !default;
$header-padding: pinpoint-spacing(small) !default;
$header-padding--tablet: pinpoint-spacing(small) pinpoint-spacing(base) !default;
$header-margin: 0 !default;
$header-gap: pinpoint-spacing(small) !default;
$header-gap--tablet: pinpoint-spacing(base) !default;
$header-justify-content: space-between !default;

.page-header {
  background: $header-background-color;
}

.panel.wrapper {
  display: none;

  @include mq($from: tablet) {
    display: block;
    color: $panel-color;
    background: $panel-background-color;
    padding: $panel-padding;
    margin: $panel-margin;
    
    .panel.header {
      .header.links {
        display: flex;
        gap: pinpoint-spacing(small);
        justify-content: flex-end;
        align-items: center;
        @include pinpoint-constrained-widths();
        
        a {
          color: $panel-color;
        }
      }
    }
  }
}

.header.content {
  display: flex;
  gap: $header-gap;
  flex-flow: row wrap;
  justify-content: $header-justify-content;
  align-items: center;
  color: $header-color;
  padding: $header-padding;
  margin: $header-margin;
  @include pinpoint-constrained-widths();
  
  @include mq($from: tablet) {
    gap: $header-gap--tablet;
    padding: $header-padding--tablet;
  }
}


@import "header__extend";
