/* ==========================================================================
   #HIDE
   ========================================================================== */

/**
 * Hide only visually, but have it available for screen readers:
 * http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
 */

.u-hidden-visually {
  @include pinpoint-hidden-visually();
}

/**
 * Hide visually and from screen readers.
 */

.u-hidden {
  display: none !important; // sass-lint:disable-line no-important
}

/**
 * Hide elements on page during load
 */
.no-display {
  display: none !important; // sass-lint:disable-line no-important
}