form {
  .field {
    &._required {
      .label::after {
        content: "*";
          color: #B71C1C;
      }
    }

    &.required {
      .field.additional {
        .label::after {
          content: unset;
        }
      }
    }
  }
}

.region-id {
    display: none;
}

.form-activate-account {
    margin: auto;
    width: 100%;
    padding: 24px;
    border-radius: 3px;
    background-color: $color-grey--light;

    @include mq($from: desktop) {
        width: 50%;
    }
}

.field-recaptcha {

    .control {
        display: flex;
    }
    input[type=checkbox] {
        width: 0;
        height: 0;
        border: 0;
        margin: 0;
        padding: 0;

        &:before {
            display: none;
        }
    }
}

form .field.required .label::after,
form .field._required .label::after {
    margin: 0 0 0 3px;
    color: $color-danger;
}
