$location-icon-pagebuilder: url("../images/icons/lawsons-locator.svg") !default;
$enhanced-link-subtext-element-icon-color: $color-secondary !default;
$enhanced-link-category-grid-header-colour: $color-black !default;
$enhanced-link-category-grid-header-colour--hover: $color-secondary !default;
$enhanced-link-category-grid-text-container-hover-colour: $color-grey--light !default;
$enhanced-link-category-grid-text-container-hover-background-colour: $color-primary !default;
$enhanced-link-category-grid-sub-text-colour: $color-white !default;
$enhanced-link-category-grid-subtext-weight: 700 !default;
$enhanced-link-category-grid-chevron: $color-white !default;
$heading-after: $color-secondary !default;

[data-content-type="heading"] {
    display: flex;
    align-items: center;
    gap: pinpoint-spacing(small);
    color: $color-black;

    &::after {
        content: "";
        flex: 1;
        height: 4px;
        background-color: $color-secondary;
    }
}

h2[data-content-type="heading"]{
    margin: 32px 0 8px;

    @include mq($from: tablet) {
        margin: 64px 0 16px;
    }
}

div[data-appearance="contained"] {
    padding: 0 16px;
}

[data-content-type=row][data-appearance=full-width] > .row-full-width-inner {
    max-width: 1920px;
}

.pb-homepage {
    &-banner {
        margin-bottom: 38px !important;

        @include mq($from: tablet) {
            margin-bottom: 67px !important;

            .pagebuilder-column {
                min-height: 100%;

                [data-content-type="image"] {
                    min-height: 100%;

                    img {
                        object-fit: cover;
                        height: 100% !important;
                    }
                }
            }
        }
    }

    &-enhanced-link {
        margin-bottom: 40px !important;
        @include mq($until: tablet) {
            margin-left: -16px !important;
            margin-right: -16px !important;
        }
        @include mq($from: tablet) {
            margin-bottom: 81px !important;
        }

        [data-content-type="pinpoint_pagebuilderenhancedlink"] {
            gap: 28px !important;
            @include mq($from: tablet) {
                gap: 26px !important;
            }

            @include mq($from: desktop) {
                gap: 52px !important;
            }
        }

        .text-container {

            @include mq($from: tablet) {
                // aspect-ratio: 1.34 / 1;
                padding: 2% !important;
                gap: 6px !important;
            }

            @include mq($from: desktop) {
                gap: 12px;
            }

            &::before {
                display: block;
                content: "";
                width: 25%;
                aspect-ratio: 1;
                background-repeat: no-repeat;
                background-position: center;
                background-size: 35%;
                background-color: $color-secondary;
                border-radius: 100%;
            }

            [data-element="enhanced_link_title_element"] {
                font-size: 27px;
                font-weight: 700;

                @include mq($from: tablet) {
                    font-size: clamp(10px, 1.5vw, 30px);
                }

                @include mq($from: desktop) {
                    font-size: clamp(10px, 1.65vw, 30px);
                }
            }

            [data-element="enhanced_link_subtext_element"] {
                font-size: 16px;
                font-weight: 700;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                gap: 3px;
                line-height: 1;

                &::after {
                    display: block;
                    content: "";
                    min-width: 12px;
                    min-height: 12px;
                    -webkit-mask-image: url(../images/icons/fa-solid/chevron-right.svg);
                    mask-image: url(../images/icons/fa-solid/chevron-right.svg);
                    -webkit-mask-size: contain;
                    -webkit-mask-repeat: no-repeat;
                    -webkit-mask-position: center;
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;
                    background-color: $enhanced-link-subtext-element-icon-color;
                    transition: all 0.2s ease-in-out;
                }

                @include mq($from: tablet) {
                    font-size: clamp(10px, 1.2vw, 18px);

                    &::after {
                        min-width: 10px;
                        min-height: 10px;
                    }
                }

                @include mq($from: desktop) {
                    font-size: clamp(10px, 1vw, 18px);

                    &::after {
                        min-width: 12px;
                        min-height: 12px;
                    }
                }
            }

            & > .buttons:empty {
                display: none;
            }
        }

        .location-icon {
            .text-container {
                border-radius: 3px;

                &::before {
                    background-image: $location-icon-pagebuilder;
                }
            }
        }

        .trade-icon {
            .text-container {
                border-radius: 3px;

                &::before {
                    background-image: url("../images/icons/lawsons-trade.svg");
                }
            }
        }
    }
}

.category-grid {
    padding-top: 33px !important;
    margin-bottom: 30px !important;

    @include mq($from: tablet) {
        padding-top: 25px !important;
        margin-bottom: 94px !important;
    }

    & > h3 {
        font-size: 27px;
        font-weight: 700;
        margin-bottom: 32px;

        @include mq($until: tablet) {
            flex-direction: column;
            align-items: flex-start;
            &::after {
                min-height: 4px;
                width: 100%;
            }
        }

        @include mq($from: tablet) {
            font-size: 30px;
            margin-bottom: 47px;
        }
    }

    .pagebuilder-column-group {
        @include mq($from: tablet) {
            gap: 32px !important;
        }
    }

    [data-content-type="pinpoint_pagebuilderenhancedlink"] {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        @include mq($from: tablet) {
            position: relative;
            aspect-ratio: 1.21 / 1;

            &:hover {
                .text-container {
                    background-color: $color-primary;
                    gap: 4px;

                    [data-element="enhanced_link_title_element"] {
                        color: $enhanced-link-category-grid-header-colour--hover;
                    }

                    [data-element="enhanced_link_subtext_element"] {
                        opacity: 1;
                        height: 28px;

                        &::after {
                            height: 10px;
                        }
                    }
                }
            }
        }

        @include mq($from: desktop) {
            &:hover {
                .text-container {
                    gap: 8px;

                    [data-element="enhanced_link_subtext_element"] {
                        &::after {
                            height: 12px;
                        }
                    }
                }
            }
        }

        figure {
            width: 100%;

            img {
                width: 100%;
            }
        }

        .text-container {
            flex: 1;
            width: 100%;
            min-height: 81px;
            padding: 8px;
            background-color: $color-grey--light;
            border-bottom: solid 4px $color-primary;
            border-radius: 0 0 3px 3px;
            overflow-y: hidden;
            justify-content: space-between;

            @include mq($from: tablet) {
                position: absolute;
                bottom: 0;
                display: flex;
                gap: 0;
                align-items: flex-start;
                justify-content: flex-end;
                min-height: 35%;
                padding: clamp(8px, 35%, 16px);
                transition: all 0.2s ease-in-out;
            }

            [data-element="enhanced_link_title_element"] {
                color: $enhanced-link-category-grid-header-colour;
                font-size: 16px;
                font-weight: 700;

                @include mq($from: tablet) {
                    font-size: clamp(12px, 1.2vw, 20px);
                    transition: all 0.2s ease-in-out;
                }

                @include mq($from: desktop) {
                    font-size: clamp(16px, 1.46vw, 26px);
                }
            }

            [data-element="enhanced_link_subtext_element"] {
                font-size: 14px;
                font-weight: $enhanced-link-category-grid-subtext-weight;
                color: $color-primary;
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                align-items: center;
                gap: 3px;

                &::after {
                    display: block;
                    content: "";
                    min-width: 12px;
                    height: 12px;
                    -webkit-mask-image: url(../images/icons/fa-solid/chevron-right.svg);
                    mask-image: url(../images/icons/fa-solid/chevron-right.svg);
                    -webkit-mask-size: contain;
                    -webkit-mask-repeat: no-repeat;
                    -webkit-mask-position: center;
                    mask-size: contain;
                    mask-repeat: no-repeat;
                    mask-position: center;
                    background-color: $color-primary;
                    transition: all 0.2s ease-in-out;
                }

                @include mq($from: tablet) {
                    font-size: clamp(12px, 1.05vw, 18px);
                    color: $color-white;
                    height: 0;
                    opacity: 0;
                    overflow-y: hidden;
                    transition: all 0.2s ease-in-out;
                    margin-bottom: -6px;

                    &::after {
                        background-color: $color-white;
                    }
                }

                @include mq($from: desktop) {
                    margin-bottom: 2px;
                }
            }
        }
    }
}

div.pagebuilder-button-primary,
a.pagebuilder-button-primary,
button.pagebuilder-button-primary {
    background-color: $color-primary;
    color: $color-white;
    padding: pinpoint-spacing(small) pinpoint-spacing(large);
}

div.pagebuilder-button-secondary,
a.pagebuilder-button-secondary,
button.pagebuilder-button-secondary {
    background-color: $color-secondary;
    color: $color-black;
    padding: pinpoint-spacing(small) pinpoint-spacing(large);
    @include pinpoint-font-size(16px);
}

.pagebuilder-slider {
    padding: 0 !important;

    button.slick-arrow {
        background: unset !important;
    }

    .slick-prev.slick-arrow {
        &::before {
            transform: translate(0, -19px);
        }
    }
}

p {
    margin: 0 0 pinpoint-spacing(small);
}

[data-content-type="row"] {

    ul {
        list-style: disc !important;
        margin: 0 0 0 32px;

        li {
            margin: 0 0 pinpoint-spacing(small);
        }
    }

    ol {
        list-style: decimal !important;
        margin: 0 0 0 32px;

        li {
            margin: 0 0 pinpoint-spacing(small);
        }
    }
}

.no-route {
    margin: 24px 0 0;

    img {
        margin: 0 auto;
    }

    svg {
        @include mq($until: tablet) {
            width: 100%;
        }
    }

    [data-content-type=heading] {
        text-align: center;
        margin: 22px auto 24px;

        &:after {
            display: none;
        }
    }

    .pagebuilder-button-primary {
        background: $color-primary;
        color: $color-white;
        font-size: 20px;
        margin: 40px auto 60px;
        @include mq($from: tablet) {
            margin: 40px auto 120px;
        }
        padding: 16px 32px;
    }
}

.store-locator {

    h1 {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: $color-black;
        background-color: $color-white;
        text-align: center;
        font-size: 27px;

        &::before,
        &::after {
            content: "";
            background-color: $color-secondary;
            height: 2px;
            width: 100%;
        }

        @include mq($until: desktop) {
            gap: 6px;

            &:before {
                display: none;
            }
        }

        @include mq($from: desktop) {
            gap: pinpoint-spacing(base);
            flex-direction: row;
            text-align: center;
            white-space: nowrap;
            font-size: 30px;
            @include pinpoint-constrained-widths();

            &::before,
            &::after {
                height: 5px;
            }
        }
    }
}

[data-content-type="products"] {

    .product-items {
        margin: 0;
        padding: 0;
    }

    .product-items.slick-slider {

        .slick-track {
            display: flex !important;
        }

        .slick-slide {
            margin: 0 12px;
            height: inherit !important;

            > div {
                height: 100%;
            }

            .product-item {
                max-width: unset;
                height: 100%;

                .product-item-actions {
                    display: flex;
                    flex-direction: row;
                    width: 100%;
                    @include mq($from: desktop) {
                        flex-wrap: nowrap;
                    }
                }

                .swatch-input {
                    display: none;
                }
            }
        }
    }

    .slick-slider {

        .slick-arrow {
            background: none !important;
        }

        .slick-prev {

            &:before {
                rotate: 180deg;
                transform: rotate(180deg) translate(0, -19px);
            }
        }

        .slick-dots {
            margin: 0;

            @include mq($until: desktop) {
                display: none !important;
            }

            li {
                background: $color-white;
                border: solid 1px $color-primary;
                border-radius: 4px;
                margin: 0 6px;

                &.slick-active {
                    background: $color-primary;
                }

                button {
                    &:hover {
                        border: 0;
                    }
                }
            }
        }
    }
}

.pagebuilder-slider.slick-slider,
[data-content-type="products"][data-appearance="carousel"] .slick-slider {

    &.slick-dotted.slick-slider {
        margin-bottom: 75px !important;
    }

    @include mq($until: desktop) {
        margin-bottom: 75px;

        .slick-arrow {
            position: absolute;
            transform: none;
            rotate: unset;
            top: 100%;

            @include mq($from: desktop) {
                top: calc(100% + 30px);
            }

            &.slick-prev:before,
            &.slick-next:before {
                background-image: url(../images/icons/icon-chevron-right--white.svg);
                background-color: $color-primary;
                background-size: 28px 28px;
                width: 28px;
                height: 28px;
            }

            &.slick-prev {
                right: 49px;
                left: unset;
                transform: none;
            }

            &.slick-next {
                right: 13px;
                left: unset;
            }
        }
    }
}

.pagebuilder-slider.slick-slider {
    @include mq($until: desktop) {
        .slick-prev:before {
            transform: rotate(180deg);
        }
    }
}

[data-content-type="products"][data-appearance="carousel"] .slick-slider {
    @include mq($until: desktop) {
        .slick-prev:before {
            rotate: unset;
            transform: rotate(180deg) translate(0);
        }

        .product-item-name {
            @include pinpoint-font-size(18px);
        }
    }
}

// Brand Slider
.brand-slider {

    .pagebuilder-column {
        flex-direction: row !important;
        padding: 8px;

        .pagebuilder-mobile-only {
            display: none !important;
        }

        a {
            display: block;
            border-radius: 8px;
        }

        figcaption {
            padding: 8px 0 0;
            font-weight: bold;
        }
    }

    .slick-arrow {

        &:before,
        &:after {
            display: none !important;
        }
    }
}

@import "pagebuilder__extend--child";
