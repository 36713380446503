.loading-mask {
    .loader {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #0006;
        background-image: url("../images/icons/loader.png");
        background-position: center;
        background-repeat: no-repeat;
        z-index: 10000;

        img {
            display: none;
        }

        p {
            color: $color-white;
            font-size: 1.5rem;
            margin-top: pinpoint-spacing(huge);
        }
    }
}