$page-title-padding: pinpoint-spacing(large);

.page-title-wrapper {
  &:not(.product) {
    @include mq($from: desktop) {
      margin-bottom: 32px;
    }

    .page-title {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: $page-title-alignment;
      align-items: center;
      font-weight: 500;

      color: $page-title-color;
      background-color: $page-title-background-color;
      text-align: $page-title-alignment;
      @include pinpoint-font-size($page-title-font-size);

      &::before,
      &::after {
        content: "";
        background-color: $page-title-border-color;
        height: 2px;
        width: 100%;
      }

      @include mq($until: desktop) {
        gap: 6px;

        &:before {
          display: none;
        }
      }

      @include mq($from: desktop) {
        gap: pinpoint-spacing(base);
        flex-direction: row;
        text-align: $page-title-alignment--desktop;
        white-space: nowrap;
        @include pinpoint-font-size($page-title-font-size--desktop);
        @include pinpoint-constrained-widths();

        &::before,
        &::after {
          height: 5px;
        }
      }
    }
  }
}

@import 'page-title__extend--child';
