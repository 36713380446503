.social-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 8px;

  .action {
    border-radius: 50%;
    width: 43px;
    height: 43px;
    padding: 12px;

    @include mq($until: tablet) {
      width: 35px;
      height: 35px;
      padding: 0;

      &::before {
        width: 20px;
        height: 20px;
      }
    }
  }

  @include mq($from: desktop) {
    order: 1;
  }
}