.page.messages {

  padding: 0 pinpoint-spacing(small);
  .success,
  .error,
  .warning,
  .notice {
    margin: 0 0 pinpoint-spacing(small);
    padding: pinpoint-spacing(small);
    position: relative;
    display: flex;
    justify-content: center;
    border-radius: $global-radius;

    a {
      text-decoration: underline;
    }

    div {
      position: relative;

      &::before {
        content: "";
        position: relative;
        display: inline-block;
        top: 2px;
        height: 16px;
        width: 16px;
        margin-right: 8px;
      }
    }
  }

  .success {
    background: $successBg;
    color: $color-white;

    div {
      color: $color-white;

      &::before {
        background: url("../images/icons/icon-success.svg");
        background-size: 16px;
      }
    }

    a {
      color: $color-white;
    }
  }

  .error {
    background: $errorBg;

    div {
      color: $error;

      &::before {
        background: url("../images/icons/icon-error.svg");
        background-size: 16px;
      }
    }
  }

  .warning,
  .notice {
    background: $noticeBg;

    div {
      color: $color-secondary;

      &::before {
        background: url("../images/icons/icon-warning.svg");
        background-size: 16px;
      }
    }
  }
  .warning {
    background: $warningBg;
  }
}

@import "notifications__extend";
