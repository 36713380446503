.lac-notification-sticky {
    position: relative;
}

.lac-notification {
    position: relative;
    background-color: $color-grey--light;
    z-index: 999999;
    border-bottom: 1px solid $color-grey--dark;
    padding: pinpoint-spacing(small);

    .top-container {
        display: flex;
        gap: pinpoint-spacing(tiny);
        justify-content: flex-start;
        align-items: center;
    }

    .lac-notification-links {
        flex: 1;
        text-align: right;
    }

    .lac-notification-close-link {
        padding: pinpoint-spacing(tiny);
        border: 1px solid $color-primary;
    }
}