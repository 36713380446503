$minicart-background-color: $color-white !default;
$minicart-border-color: $color-grey--dark !default;
$minicart-position-top: 0 !default;
$minicart-position-top--desktop: 55px !default;
$minicart-position-right: 0 !default;
$minicart-position-right--desktop: 0 !default;
$minicart-header-color: $color-white !default;
$minicart-header-background-color: $color-primary !default;
$minicart-top-offset: 72px !default;

.minicart-wrapper {

  @include mq($from: desktop) {
    order: 1;
  }

  position: relative; // Give the counter a parent to position against

  .block-minicart {
    display: none; // Hide minicart during initial page-load
    background: $minicart-background-color;
    border: 1px solid $minicart-border-color;
    position: fixed;
    z-index: 10;

    @include mq($until: desktop) {
      top: $minicart-position-top;
      right: $minicart-position-right;
      bottom: 0;
      width: 100%;
      max-width: 100%;
    }

    @include mq($from: desktop) {
      position: absolute;
      top: $minicart-position-top--desktop;
      right: $minicart-position-right--desktop;
      min-width: max-content;
      width: 640px;
      min-width: 100%;

      &::before {
        content: "";
        position: absolute;
        top: -22px;
        right: 12px;
        height: 0;
        width: 0;
        border: 11px solid;
        border-color: transparent transparent $minicart-header-background-color transparent;
        z-index: 99;
      }

      &::after {
        content: "";
        position: absolute;
        top: -24px;
        right: 11px;
        height: 0;
        width: 0;
        border: 12px solid;
        border-color: transparent transparent $minicart-border-color transparent;
        z-index: 98;
      }
    }
  }

  #minicart-content-wrapper {
    height: 100%;

    .block-title {
      padding: pinpoint-spacing(base);
      background-color: $minicart-header-background-color;
      color: $minicart-header-color;

      .qty {
        display: none;
      }
    }

    .block-content {
      display: flex;
      flex-direction: column;
      gap: pinpoint-spacing(small);
      padding: pinpoint-spacing(small);

      @include mq($until: desktop) {
        height: calc(100vh - #{$minicart-top-offset});
      }
    }

    #btn-minicart-close {
      position: absolute;
      top: pinpoint-spacing(small);
      right: pinpoint-spacing(small);

      @include mq($from: desktop) {
        display: none;
      }
    }
  }

  .items-total {

  }

  .subtotal {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .action.checkout,
  .action.viewcart {
    width: 100%;
  }

  .minicart-items-wrapper {
    @include mq($from: desktop) {
      max-height: 300px;
      overflow-y: auto;
    }
  }

  .minicart-items {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: pinpoint-spacing(small);

    &-wrapper {
      flex: 1;
    }

    .product-item {
      & > .product {
        display: flex;
      }
    }

    .product-item-photo {

      .product-image-container,
      .product-image-wrapper {
        display: inline-block;
      }
    }

    .product-item-details {
      flex: 1;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-end;
    }

    .product-item-name {
      flex: 1 0 100%;
    }

    .product.options {
      flex: 1 0 100%;

      &.list {
        display: grid;
        grid-template-columns: max-content auto;
        gap: 6px;

        .label {
          &::after {
            content: ":";
          }
        }
      }
    }

    .product-item-pricing {
      flex: 1 1 45%;
    }

    .product.actions {
      flex: 1 1 45%;
      display: flex;
      justify-content: flex-end;
      gap: pinpoint-spacing(small);
    }
  }
}

@import "minicart__extend";
