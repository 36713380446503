/* ==========================================================================
   # TYPOGRAPHY
	 ========================================================================== */

html {
  font-family: $font-family--body;
  color: $color-black;
}

pre {
  font-size: 0.8rem;
  white-space: pre-line;
  border: 0.1rem solid rgba(119, 119, 119, 0.3);
  border-radius: $global-radius;
  padding: 0.5rem;
}

small {
  font-size: smaller;
}

strong {
  font-weight: bold;
  display: inline;
}
