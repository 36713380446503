$icon-links-cart-background-color-hover: #1ca153 !default;
$icon-links-cart-border-color: 2px solid rgba(255, 255, 255, 0.5) !default;

.icon-links {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  @include mq($from: tablet) {
    order: 1;
    font-size: 14px;
    line-height: 1.5;
    gap: 32px;
    padding: 12px 0;

    .action.book-appointment,
    .action.store-locator,
    .action.my-account,
    .action.quote-list {
      font-weight: 500;
      padding: 0 !important;
      gap: 3px;
    }

    .action.showcart {
      @include mq($from: desktop) {
        font-weight: 500;
        gap: 3px;
        padding: 16px !important;
        margin: -16px !important;
        border-radius: 3px;
        transition: background-color 0.3s ease-in-out;

        &.active {
          background-color: $icon-links-cart-background-color-hover;
        }
      }
    }

    .minicart-wrapper {
      border-left: $icon-links-cart-border-color;
      padding-left: 32px;
    }
  }
}
