/* ==========================================================================
   #ASPECT-RATIO
   ========================================================================== */

/**
 * Utility classes to easily add aspet ratios to elements:
 *
 *   <div class="u-aspect-ratio-16/9">ensure this element is always in a 16:9 ratio</div>
 *
 */

.u-aspect-ratio-16\/9,
.u-aspect-ratio-16-9 {
  aspect-ratio: 16/9 !important; // sass-lint:disable-line no-important
}

.u-aspect-ratio-4\/3,
.u-aspect-ratio-4-3 {
  aspect-ratio: 4/3 !important; // sass-lint:disable-line no-important
}

.u-aspect-ratio-2\/1,
.u-aspect-ratio-2-1 {
  aspect-ratio: 2/1 !important; // sass-lint:disable-line no-important
}

.u-aspect-ratio-1\/1,
.u-aspect-ratio-1-1 {
  aspect-ratio: 1/1 !important; // sass-lint:disable-line no-important
}
