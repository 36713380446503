.payment-icons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: pinpoint-spacing(tiny);

  @include mq($from: desktop) {
  justify-content: flex-start;
  }

  img {
    height: 45px;
    @include mq($until: tablet) {
      width: 40px;
    }
  }
  img.amex-img{
    @include mq($until: tablet) {
        width: 28px;
        height: 28px;
    }
  }
}
