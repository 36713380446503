._with-tooltip {
  position: relative;
}

.field-tooltip {
  position: absolute;
  top: pinpoint-spacing(small);
  right: pinpoint-spacing(small);
  display: inline-block;
  cursor: pointer;
  
  &._active {
    z-index: 100;
    
    .field-tooltip-content {
      display: block;
    }
  }
  
  .label {
    @include pinpoint-hidden-visually();
  }
  
  .field-tooltip-action {
    &::before {
      content: "";
      mask-image: url("../images/icons/info.svg");
      background-color: $color-primary;
      width: pinpoint-spacing(base);
      height: pinpoint-spacing(base);
      display: inline-block;
    }
  }
  
  .field-tooltip-content {
    display: none;
    width: 270px;
    position: absolute;
    left: pinpoint-spacing(large);
    top: -#{pinpoint-spacing(small)};
    padding: pinpoint-spacing(small);
    background: $color-white;
    border: 1px solid $color-grey;
    border-radius: $global-radius;
    box-shadow: 0 12px 24px -4px rgba(35, 31, 32, .1), 0 8px 16px -8px rgba(35, 31, 32, .1);
    @include pinpoint-font-size(14px);
    z-index: 2;
    
    &::before,
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 12px;
      left: -21px;
      width: 0;
      height: 0;
      border: 10px solid transparent;
      z-index: 3;
    }

    &::before {
      border-right-color: $color-grey;
    }
    
    &::after {
      border-right-color: $color-white;
      left: -20px;
    }

    @include mq($until: desktop) {
      left: auto;
      right: -10px;
      top: 40px;
      
      &::before,
      &::after {
        left: auto;
        top: 0;
        right: 16px;
        width: 0;
        height: 0;
        margin-top: -#{pinpoint-spacing(small)};
        border: pinpoint-spacing(tiny) solid transparent;
      }
      
      &::before {
        border-bottom-color: $color-grey;
      }
      
      &::after {
        border-bottom-color: $color-white;
        top: 1px;
      }
    }
  }
}

  

._keyfocus .field-tooltip .field-tooltip-action:focus {
  z-index: 100;
  
  &::before {
    color: #333;
  }

  & + .field-tooltip-content {
    display: block;
  }  
}

@import "tooltip__extend";
