// sass-lint:disable no-duplicate-properties

@mixin mainHeader {
  @include pinpoint-font-size(32px);
  line-height: 40px;
  font-weight: 600;
  letter-spacing: 0;

  @include mq($from: desktop) {
    @include pinpoint-font-size(48px);
    line-height: 56px;
  }
}

@mixin subHeader {
  @include pinpoint-font-size(24px);
  line-height: 32px;
  font-weight: 600;
  letter-spacing: 0;

  @include mq($from: desktop) {
    @include pinpoint-font-size(32px);
    line-height: 40px;
  }
}

@mixin thirdHeader {
  @include pinpoint-font-size(16px);
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
}

@mixin paragraph {
  @include pinpoint-font-size(14px);
  color: $color-secondary;
  letter-spacing: 0;
  line-height: 22px;
  margin: 0;
}

@mixin paragraphSmall {
  @include pinpoint-font-size(12px);
  line-height: 20px;
  color: $color-secondary;
  letter-spacing: 0;
}

@mixin paragraphLarge {
  @include paragraph;
  @include pinpoint-font-size(24px);
  color: $textLight;
  line-height: 32px;
}
