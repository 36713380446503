/* ==========================================================================
   #ACTIONS
   ========================================================================== */

// action-name, background-color, text-color, icon-filename, icon-position, hide-label (bool)
$action-edit: "edit" $color-secondary $color-white "fa-solid/pen.svg" before !default;
$action-edit-cart: "action-edit" $color-secondary $color-white "fa-solid/pen.svg" before true !default; // Class is action-edit on the basket page - Needs review when ability to add multiple classes is added
$action-delete: "delete" $color-secondary $color-white "fa-solid/trash.svg" before true !default;
$action-delete-cart: "action-delete" $color-secondary $color-white "fa-solid/trash.svg" before true !default; // Class is action-delete on the basket page - Needs review when ability to add multiple classes is added
$action-remove: "remove" $color-white $color-secondary "fa-solid/trash.svg" before true !default;
$action-clear: "clear" $color-secondary $color-white "fa-solid/trash.svg" before !default;
$action-viewcart: "viewcart" $color-secondary $color-white "basket.svg" !default;
$action-checkout: "checkout" $color-primary $color-white "basket.svg" !default;
$action-multicheckout: "multicheckout" $color-secondary $color-white !default;
$action-tocart: "tocart" null $color-white "basket.svg" before !default;
$action-showcart: "showcart" $color-secondary $color-white "basket.svg" above true !default;
$action-towishlist: "towishlist" $color-secondary $color-white "icon-wishlist.svg" before true !default;
$action-towishlistsplit: "label.action.split" $color-secondary $color-white "icon-wishlist.svg" before true !default;
$action-towishlistsplittoggle: "action.toggle.change" $color-secondary $color-white "arrow-down.svg" before true !default;
$action-tocompare: "tocompare" $color-grey $color-white "icon-compare.svg" before true !default;
$action-search: "search" $color-secondary $color-white "search.svg" before true !default;
$action-nav-toggle: "nav-toggle" $color-secondary $color-white "icon-menu.svg" before true !default;
$action-nav-toggle-close: "nav-toggle.active" $color-secondary $color-white "icon-menu-close.svg" before true !default;
$action-sorter-ascending: "sort-asc" $color-secondary $color-white "fa-solid/arrow-up.svg" before true !default;
$action-sorter-descending: "sort-desc" $color-secondary $color-white "fa-solid/arrow-down.svg" before true !default;
$action-close: "close" $color-secondary $color-white "fa-solid/x.svg" before true !default;
$action-update: "update" $color-grey--light $color-black "fa-solid/floppy-disk.svg" before !default;
$action-share: "share" $color-secondary $color-white "fa-solid/share.svg" before !default;
$action-continue: "continue" $color-grey--light $color-black;

$actions-list:
  $action-edit,
  $action-edit-cart,
  $action-delete,
  $action-delete-cart,
  $action-remove,
  $action-clear,
  $action-tocart,
  $action-viewcart,
  $action-checkout,
  $action-multicheckout,
  $action-showcart,
  $action-towishlist,
  $action-towishlistsplit,
  $action-towishlistsplittoggle,
  $action-tocompare,
  $action-nav-toggle,
  $action-nav-toggle-close,
  $action-search,
  $action-sorter-ascending,
  $action-sorter-descending,
  $action-close,
  $action-update,
  $action-share,
  $action-continue !default;

$child-actions-list: null !default;
$parent-actions-list: null !default;
$custom-actions-list: null !default;
$actions: null !default;


// If child actions list is not null
@if $child-actions-list !=null {
  $custom-actions-list: join($parent-actions-list, $child-actions-list);
}

// If child actions list is null but parent actions list is not null
@if $child-actions-list == null and $parent-actions-list != null {
  $custom-actions-list: $parent-actions-list;
}

// If custom actions list is null
@if $custom-actions-list == null {
  $actions: $actions-list;
} @else {
  $actions: join($actions-list, $custom-actions-list);
}

.action:not(.compare) {
  display: inline-flex; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  padding: pinpoint-spacing(small);
  gap: pinpoint-spacing(tiny);
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  transition: $global-transition;
  border-radius: $global-radius;
  justify-content: center;
  align-items: center;

  span {
    white-space: nowrap;
  }
}


  /* Style variants
     ========================================================================== */

  @each $name, $background-color, $color, $icon, $icon-position, $hide-label in $actions {
    .action.#{$name} {
      color: $color;
      @if $background-color == null {
      }
      @else {
        background-color: $background-color;
        box-shadow: 0px 0px 0px 1px darken($background-color, 5%) inset;
      }


      &,
      &:hover,
      &:active,
      &:focus {
        text-decoration: none; /* [4] */
      }

      @if $icon {
        &:before {
          content: "";
          width: pinpoint-spacing(base);
          height: pinpoint-spacing(base);
          display: inline-flex;
          background-color: $color;
          mask-image: url("../images/icons/#{$icon}");
          mask-size: contain;
          mask-repeat: no-repeat;
          mask-position: center;
        }

        @if $icon-position == before {
          flex-direction: row;
        } @else if $icon-position == after {
          flex-direction: row-reverse;
        } @else if $icon-position == above {
          flex-direction: column;
          align-items: center;
        } @else if $icon-position == below {
          flex-direction: column-reverse;
          align-items: center;
        }
      }


      &:hover,
      &:focus {
        @if $background-color == null {
          color: darken($color, 10%);
          @if $icon {
            &:before {
              background-color: darken($color, 10%);
            }
          }
        } @else {
          background-color: darken($background-color, 10%);
        }
      }

      @if $hide-label == true {
        & > span:not(.counter) {
          @include pinpoint-hidden-visually();
        }
      } @else if $hide-label == mobile {
        & > span:not(.counter) {
          @include mq($until: tablet) {
            @include pinpoint-hidden-visually();
          }
        }
      }
    }
  }

@import "actions__extend";
