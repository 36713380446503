///* ========================================================================
//   #CLEARFIX
//   ======================================================================== */

// Mixin to drop micro clearfix into a selector. Further reading:
// http://www.cssmojo.com/the-very-latest-clearfix-reloaded/
//
// .usage {
//   @include pinpoint-clearfix();
// }

@mixin pinpoint-clearfix() {

  &::after {
    content: "" !important; // sass-lint:disable-line no-important // sass-lint:disable-line no-important
    display: block !important; // sass-lint:disable-line no-important
    clear: both !important; // sass-lint:disable-line no-important
  }

}
