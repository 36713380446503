.category-links {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  padding: 16px;

  @include mq($from: tablet) {
    gap: 32px;
    grid-template-columns: repeat(4, 1fr);
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;

    @include mq($from: tablet) {
      position: relative;
      aspect-ratio: 1.21 / 1;

      &:hover {
        .category-links__text-container {
          background-color: $color-primary;

          .category-links__title {
            color: $color-secondary;
          }

          .category-links__browse {
            opacity: 1;
            height: 27px;

            &::after {
              height: 12px;
            }
          }
        }
      }
    }
  }

  &__image {
    width: 100%;

    img {
      width: 100%;
    }
  }

  &__text-container {
    flex: 1;
    width: 100%;
    min-height: 81px;
    padding: 8px;
    background-color: $color-grey--light;
    border-bottom: solid 4px $color-primary;
    border-radius: 0 0 3px 3px;
    overflow-y: hidden;

    @include mq($from: tablet) {
      position: absolute;
      bottom: 0;
      display: flex;
      align-items: flex-end;
      min-height: 80px;
      padding: 16px;
      transition: all 0.2s ease-in-out;
    }

    @include mq($from: desktop) {
      min-height: 101px;
    }

    a {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-end;
    }
  }

  &__title {
    font-size: 16px;
    font-weight: 700;
    color: $color-black;

    @include mq($from: tablet) {
      font-size: 20px;
      transition: all 0.2s ease-in-out;
    }

    @include mq($from: desktop) {
      font-size: 26px;
    }
  }

  &__browse {
    font-size: 14px;
    font-weight: 700;
    color: $color-primary;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    gap: 6px;

    @include mq($from: tablet) {
      font-size: 18px;
      color: $color-white;
      height: 0;
      opacity: 0;
      overflow-y: hidden;
      transition: all 0.2s ease-in-out;
    }

    &::after {
      display: block;
      content: "";
      min-width: 12px;
      height: 12px;
      -webkit-mask-image: url(../images/icons/fa-solid/chevron-right.svg);
      mask-image: url(../images/icons/fa-solid/chevron-right.svg);
      -webkit-mask-size: contain;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-position: center;
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
      transition: all 0.2s ease-in-out;
      background-color: $color-primary;

      @include mq($from: tablet) {
        height: 0;
        background-color: $color-grey--light;
      }
    }
  }
}

@import "category-links__extend";
