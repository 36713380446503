.action,
.submit,
.btn,
.button {
  &:hover {
    text-decoration: none;
  }
}

a.action.tocart.primary {
    background-color: $color-primary;
}
