/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

input[type="submit"],
button,
.button {
  display: inline-block; /* [1] */
  vertical-align: middle; /* [2] */
  font: inherit; /* [3] */
  text-align: center; /* [4] */
  margin: 0; /* [4] */
  cursor: pointer; /* [5] */
  padding: pinpoint-spacing(small) pinpoint-spacing(base);
  transition: $global-transition;
  border-radius: $global-radius;

  /* Style variants
    ========================================================================== */

  @each $name, $color in $color-palette {
    &.#{$name} {
      color: $color-white;
      background-color: $color;
      &,
      &:hover,
      &:active,
      &:focus {
        text-decoration: none; /* [4] */
      }

      &:hover,
      &:focus {
        background-color: darken($color, 10%);
      }
    }
  }
}

@import "buttons__extend";
