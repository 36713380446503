$menu-hover-item-background-color: $color-white !default;
$menu-hover-item-text-color: $color-primary !default;
$menu-hover-item-text-color--hover: #057D32 !default;
$main-menu-list-justify-content--desktop: center !default;
$main-menu-link-font-weight--desktop: 500 !default;
$main-menu-link-font-size--desktop: 16px !default;
$menu-highlight-background-color: transparent !default;
$menu-highlight-text-color: $color-secondary !default;
$menu-highlight-hover-background-color: $color-white !default;
$menu-highlight-hover-text-color: $color-primary !default;

.main-menu {
  @include mq($from: desktop) {
    box-shadow: 0 3px 6px rgba(0, 98, 176, 0.1);
  }

  .main-menu__list {
    @include mq($until: desktop) {
      border-left: $main-menu-item-border;
      border-right: $main-menu-item-border;
      position: static;
      overflow: visible;
      height: auto;
    }

    @include mq($from: desktop) {
        justify-content: $main-menu-list-justify-content--desktop;
      gap: 32px;
      min-height: 65px;
    }
  }

  .main-menu__item {
    @include mq($from: desktop) {
      align-items: stretch;
    }

    &:hover {
      > .main-menu__link {
        @include mq($from: desktop) {
          background-color: $menu-hover-item-background-color;
          color: $menu-hover-item-text-color--hover;
        }
      }
    }

    &.highlight-item {
      .main-menu__link {
        @include mq($from: desktop) {
            background-color: $menu-highlight-background-color;
          color: $menu-highlight-text-color;

          &:hover {
              background-color: $menu-highlight-hover-background-color;
            color: $menu-highlight-hover-text-color;
          }
        }
      }
    }

    &.mobile-extra-links {
      border: 0;

      .main-menu__link,
      .main-menu__inner-item--all,
      .submenu-open,
      .submenu-back {
        display: none;
      }

      .main-menu__inner-list {
        position: unset;
        display: block;
        overflow-x: unset;
        transform: unset;
        height: unset;
      }

      .mobile-heading {
        padding-top: 24px;

        .main-menu__inner-link {
          font-size: 20px;
          font-weight: 500;
          color: $color-primary;
        }
      }

      @include mq($from: desktop) {
        display: none;
      }
    }
  }

  .main-menu__link {
    @include mq($until: desktop) {
      font-size: 14px;
    }

    @include mq($from: desktop) {
      font-weight: $main-menu-link-font-weight--desktop;
      display: flex;
      align-items: center;
      line-height: 1.2;
      font-size: $main-menu-link-font-size--desktop;
    }
  }

  .main-menu__inner-list {

    @include mq($until: desktop) {
      display: flex;
      flex-direction: column;
      height: calc(100vh - 118px);
      border-left: $main-menu-item-border;
      border-right: $main-menu-item-border;
    }
  }

  .main-menu__inner-list--level1 {
    @include mq($from: desktop) {
      padding: 20px;
        box-shadow: 0 3px 6px rgba(0, 98, 176, 0.1);
      max-width: 1796px;
        transition: all 0.5s ease-in-out;
    }
  }

  .main-menu__inner-item {
    &--parent {
      & > .main-menu__inner-link {
        @include mq($from: desktop) {
          font-size: 18px;
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
            align-content: center;
          margin-bottom: 6px;
          gap: 0;
          transition: all 0.2s ease-in-out;
        }

        &::after {
          @include mq($from: desktop) {
            content: "";
            width: 24px;
            height: 24px;
            display: inline-flex;
            background-color: $color-primary;
            mask-image: url(../images/icons/icon-chevron.svg);
            mask-size: contain;
            mask-repeat: no-repeat;
            mask-position: center;
            transform: rotate(-90deg);
            transition: all 0.2s ease-in-out;
            translate: 0 2px;
          }
        }

        &:hover {
          &::after {
            background-color: fade-out($color-primary, 0.4);
            translate: 5px 2px;
          }
        }
      }
    }

    &--all {
      @include mq($until: desktop) {
        order: 1;

        .main-menu__inner-link {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          color: $color-primary;
          font-weight: bold;
          font-size: small;
          line-height: 1;

          &::after {
            @include mq($until: desktop) {
              content: "";
              width: 20px;
              height: 20px;
              display: inline-flex;
              background-color: $color-primary;
              mask-image: url(../images/icons/icon-chevron.svg);
              mask-size: contain;
              mask-repeat: no-repeat;
              mask-position: center;
              transform: rotate(-90deg);
            }
          }
        }
      }
    }

    &.push-right {
      @include mq($until: desktop) {
        order: 2;
      }

      @include mq($from: desktop) {
        grid-row-end: 3;
      }

        .main-menu__inner-link {
            display: none;
        }
    }
  }

  .main-menu__inner-link {
    @include mq($until: desktop) {
      font-size: 14px !important;
    }

    @include mq($from: desktop) {
      padding: 6px 12px;
      line-height: 1.25;
      font-size: 14px;
    }
  }

  .parent-cat-title {
    @include mq($until: desktop) {
      font-size: 20px;
      font-weight: 500;
      padding: 0 12px 12px;
      border-bottom: $main-menu-inner-item-border;
    }
    @include mq($from: desktop) {
      display: none;
    }
  }


  .submenu-back {
    @include mq($until: desktop) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      line-height: 1;
      margin-left: -6px;
    }

    &::before {
      @include mq($until: desktop) {
        content: "";
        width: 24px;
        height: 24px;
        display: inline-flex;
        background-color: $color-primary;
        mask-image: url(../images/icons/icon-chevron.svg);
        mask-size: contain;
        mask-repeat: no-repeat;
        mask-position: center;
        transform: rotate(90deg);
      }
    }
  }
}

@import "main-menu__extend--child";
