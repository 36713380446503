.customer-menu {
  width: 250px;
  position: absolute;
  top: 50px;
  right: 0;
  background-color: $color-white;
  border: 1px solid $border;
  border-radius: $global-radius;
  padding: 16px;
  list-style: none;

  &::before {
    content: "";
    display: none;
    position: absolute;
    top: -22px;
    right: 9px;
    height: 0;
    width: 0;
    border: 11px solid;
    border-color: transparent transparent $color-white transparent;
    z-index: 99;

    @include mq($from: desktop) {
      display: block;
    }
  }

  &::after {
    content: "";
    display: none;
    position: absolute;
    top: -24px;
    right: 8px;
    height: 0;
    width: 0;
    border: 12px solid;
    border-color: transparent transparent $border transparent;
    z-index: 98;

    @include mq($from: desktop) {
      display: block;
    }
  }

  li {
    margin-bottom: 12px;

    a {
      &:hover {
        span {
          box-shadow: inset 0 -2px 0 0 $color-primary;
        }
      }
    }
  }
}

@import "customer-menu__extend";
