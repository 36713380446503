/* ==========================================================================
   # TOOLBAR
   ========================================================================== */

$toolbar-modes-label-show: false !default;
$toolbar-sorter-label-show: false !default;

.toolbar {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: pinpoint-spacing(small);
  justify-content: space-between;
  align-items: center;
  margin-bottom: pinpoint-spacing(small);
  padding: pinpoint-spacing(small);
}

.modes {
  @if $toolbar-modes-label-show != true {
    &-label {
      @include pinpoint-hidden-visually();
    }
  }

  &-mode {
    display: inline-flex;
    vertical-align: middle;
    font: inherit;
    text-align: center;
    gap: pinpoint-spacing(tiny);
    margin: 0;
    cursor: pointer;
    transition: $global-transition;
    border-radius: $global-radius;
    justify-content: center;
    align-items: center;

    &:before {
      content: "";
      width: pinpoint-spacing(base);
      height: pinpoint-spacing(base);
      display: inline-flex;
      background-color: $color-grey;
      mask-size: contain;
      mask-repeat: no-repeat;
      mask-position: center;
    }

    &.active,
    &:hover {
      &::before {
        background-color: $color-primary;
      }
    }

    span {
      @include pinpoint-hidden-visually();
    }
  }

  .mode-grid {
    &::before {
      mask-image: url("../images/icons/fa-solid/table-cells.svg");
    }
  }

  .mode-list {
    &::before {
      mask-image: url("../images/icons/fa-solid/list.svg");
    }
  }
}

.toolbar-amount {
  @include mq($until: tablet) {
    display: none
  }
}

.sorter {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: pinpoint-spacing(small);

  @if $toolbar-sorter-label-show != true {
    &-label {
      @include pinpoint-hidden-visually();
    }
  }
}

.limiter {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  
  &-options {
    min-width: 64px;
  }
  
  @include mq($from: tablet) {
    justify-content: flex-end;
  }
}

@import "toolbar__extend";
