.message-bar-container {
  width: 100%;
  div[data-content-type="row"] {
    width: 100%;
    word-wrap: break-word;
    > div:first-of-type {
      min-height: 50px;
    }
    div[data-content-type="text"] {
      max-width: var(--grid-max);
      margin: 0 auto;
      text-align: center;
    }
  }
}

.message.global.noscript {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  gap: 0.5rem;
  border-bottom: 1px solid grey;
  background-color: lightgrey;

  .content p {
    font-size: unset;
    color: unset;
  }

  &::before {
    display: block;
    content: "🔧";
  }
}

@import "messages__extend";
